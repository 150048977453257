import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExempleService {

  activeExemple:string;//html, threattools, hack, frame, web, database, network, security
  activeExempleSubject:Subject<string>;

  allowedExemple:Array<string>;

  constructor() {
    this.activeExemple = "html";
    this.activeExempleSubject = new Subject<string>();
    this.allowedExemple = ["html","threat","hack","frame","web","database","network","security"];
  }

  emitActiveExemple() {
    this.activeExempleSubject.next(this.activeExemple);
  }

  setActiveExemple(val:string) {
    if(this.allowedExemple.indexOf(val)!=-1) this.activeExemple=val;
    this.emitActiveExemple();
  }
}
