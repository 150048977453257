import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Connection } from '../models/dataset.model';

@Injectable({
  providedIn: 'root'
})
export class IpinfoService {

  connection:Connection;
  connSubject:Subject<Connection>;

  constructor() {
    this.connection = null;
    this.connSubject = new Subject<Connection>();
  }

  emitConnection() {
    this.connSubject.next(this.connection);
  }

  setConnection(conn:Connection) {
    this.connection = conn;
    this.emitConnection();
  }
}
