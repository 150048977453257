<div class="container">
    <div class="col-span-2 top">
        <div class="txt center border-b">Security alerts in progress:</div>
    </div>
    <div class="flux-container" *ngIf="fluxLoad">
        <a [href]="item.link" target="_blank" rel="noopener noreferrer" class="news" *ngFor="let item of fluxRss">
            <div class="head">{{ item.title }}</div>
            <div class="content" [innerHTML]="item.content"></div>
            <div class="date">{{ item.pubDate }}</div>
        </a>
    </div>
</div>
