import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";  

import { AppComponent } from './app.component';
import { ConnectionComponent } from './datas/connection/connection.component';
import { CvComponent } from './cv/cv.component';
import { PersonalComponent } from './personal/personal.component';
import { FluxComponent } from './flux/flux.component';
import { ExempleComponent } from './cv/exemple/exemple.component';
import { ExperienceComponent } from './cv/experience/experience.component';
import { VersionComponent } from './version/version.component';
import { IntroComponent } from './intro/intro.component';
import { MobileComponent } from './cv/mobile/mobile.component';

@NgModule({
  declarations: [
    AppComponent,
    ConnectionComponent,
    CvComponent,
    PersonalComponent,
    FluxComponent,
    ExempleComponent,
    ExperienceComponent,
    VersionComponent,
    IntroComponent,
    MobileComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
