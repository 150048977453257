export class Experience {

    type:string;

    office:string;
    title:string;
    company:string;

    from:number;
    to:number;

    constructor(obj:object) {
        this.office = obj['office'];
        this.title = obj['title'];
        this.company = obj['company'];
        this.from = obj['from'];
        this.to = obj['to'];
        this.type = obj['type'];
    }
}