import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flux',
  templateUrl: './flux.component.html',
  styleUrls: ['./flux.component.scss']
})
export class FluxComponent implements OnInit {

  fluxRss:Array<object>;
  fluxLoad:boolean;

  constructor(private http: HttpClient) {
    this.fluxLoad = false;

    http.get("https://rss2json.com/api.json?rss_url=https://www.cert.ssi.gouv.fr/alerte/feed/").subscribe((val)=>{
      if(val['status']=="ok") {
        this.fluxRss = val['items'];
        this.fluxLoad = true;
        for (let i = 0; i < this.fluxRss.length; i++) {
          this.fluxRss[i]['title'] = this.fluxRss[i]['title'].substring(22,this.fluxRss[i]['title'].search(/ \(\d{2}/gi));
          this.fluxRss[i]['content'] = this.fluxRss[i]['content'].substring(0,180)+"..."
        }
        //console.log(val)
        //console.log(this.fluxRss);
      }
    });
  }

  ngOnInit(): void {
  }

}
