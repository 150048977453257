
export class Data {
    name:string;
    score:number;// -1500 < [ score ] < 1500
    fraud:number;
    poi:number;
    valid:number;

    constructor(name:string,score:number,fraud:number,poi:number,valid:number) {
        this.name = name ? name.replace(/[àâä]/gi,'a').replace(/[éèëê]/gi,'e').replace(/[îï]/gi,'i').replace(/[ôö]/gi,'a').replace(/[ùûü]/gi,'u').toLowerCase() : "";
        this.score = score ? score : 0;
        this.fraud = fraud ? fraud : 0;
        this.poi = poi ? poi : 0;
        this.valid = valid ? valid : 0;
    }
}

export class Connection {
    ip:number;
    isp:string;
    as:string;
    org:string;
    city:string;
    country:string;
    countryCode:string;
    lon:number;
    lat:number;
    os:string;
    osVersion:string;
    browser:string;
    browserVersion:string;
    language:string;
    userAgent:string;
    device:string;
    deviceType:string;
    screenOrientation:string;
    screenSize:string;

    score:number;

    constructor() {
        this.score = 0;
    }

    setLanguage(val:string) {
        this.language = val;
    }
}

export class DataSet {
    loss:number;
    datas:Array<Data>;
    names:Array<String>;

    constructor(fileTxt:string) {
        this.datas = this.getDatasFromTxt(fileTxt);
        this.names = this.datas.map((e)=>{return e.name});
        this.loss = 0.8;
    }
    
    getDatasFromTxt(fileTxt:string):Array<Data> {
        let temp = [];
        let file = JSON.parse(fileTxt);
        file.forEach((d:any) => {
        temp.push(new Data(d.nom,d.score,d.valid,d.poi,d.fraud));
        });
        return temp;
    }

    getScore(val:string) {
        const ind = this.names.indexOf(val);
        if(ind!=-1) {
            return this.datas[ind].score;
        } else {
            return null;
        }
    }
}

export class Prenom {
    name:string;
    gender:string;
    country:string;
    occurence:number;

    constructor(name:string,gender:string,country:string,occ:number) {
        this.name = name ? name.replace(/[àâä]/gi,'a').replace(/[éèëê]/gi,'e').replace(/[îï]/gi,'i').replace(/[ôö]/gi,'a').replace(/[ùûü]/gi,'u').toLowerCase() : "";
        this.country = country ? country : "";
        this.gender = gender ? gender : "";
        this.occurence = occ ? occ : 0;
    }

}

export class PrenomList {
    datas: Array<Prenom>;
    names: Array<string>;

    constructor(fileTxt:string) {
        this.datas = this.getDatasFromTxt(fileTxt);
        this.names = this.datas.map((e)=>{return e.name;});
    }
    
    getDatasFromTxt(fileTxt:string):Array<Prenom> {
        let temp = [];
        let file = JSON.parse(fileTxt);
        file.forEach((d:any) => {
            temp.push(new Prenom(d.name,d.gender,d.country,d.occ));
        });
        return temp;
    }

    isIn(val:string):Promise<number> {
        return new Promise((resolve, reject) => { 
            let res = this.names.indexOf(val); 
            resolve(res);
        });
    }
}

export class Nom {
    name:string;
    count:number;

    constructor(name:string,count:number) {
        this.name = name ? name.replace(/[àâä]/gi,'a').replace(/[éèëê]/gi,'e').replace(/[îï]/gi,'i').replace(/[ôö]/gi,'a').replace(/[ùûü]/gi,'u').toLowerCase() : "";
        this.count = count ? count : 0;
    }
}

export class NomList {
    datas: Array<Nom>;
    names: Array<string>;

    constructor(fileTxt:string) {
        this.datas = this.getDatasFromTxt(fileTxt);
        this.names = this.datas.map((e)=>{return e.name});
    }
    
    getDatasFromTxt(fileTxt:string):Array<Nom> {
        let temp = [];
        let file = JSON.parse(fileTxt);
        file.forEach((d:any) => {
            temp.push(new Nom(d.name.replace(' ',''),d.count));
        });
        return temp;
    }

    isIn(val:string):Promise<number> {
        return new Promise((resolve, reject) => { 
            let res = this.names.indexOf(val); 
            resolve(res);
        });
    }
}