import { Component, OnInit } from '@angular/core';
import { Experience } from 'src/app/models/experience.model';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  expBrut:any;

  exp:Array<Experience>;

  constructor() {
    this.expBrut = [
      {'type':'Formation','title':'Blue Team Level 1','office':'Remote','company':'securityblue.team','from':2022,'to':2023},
      {'type':'Job','title':'SOAR engineer','office':'Remote','company':'Orange CyberDefense','from':2021,'to':new Date().getFullYear()},
      {'type':'Formation','title':'Graduate Développeur Full Stack Angular','office':'Remote','company':'Studi.fr','from':2021,'to':2021},
      {'type':'Job','title':'Analyste fraude et sécurité','office':'Marseille','company':'Expedia France','from':2014,'to':2021},
      {'type':'Job','title':'Conseiller Multimédia','office':'Marseille','company':'Certicall (Free)','from':2013,'to':2014},
      {'type':'Formation','title':'IUT Réseau & Télécommunication','office':'Marseille','company':'IUT de Luminy','from':2010,'to':2013},
      {'type':'Job','title':'Téléconseiller au 1013','office':'Avignon','company':'Orange','from':2012,'to':2012},
      {'type':'Formation','title':'BAC Sciences et Technologies Industrielles','office':'Arles','company':'nc','from':2009,'to':2010},
    ];

    this.exp = [];

    for (let i = 0; i < this.expBrut.length; i++) {
      this.exp.push(new Experience(this.expBrut[i]));
    }
   }

  ngOnInit(): void {
  }

}
