import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as mapbox from 'mapbox-gl';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataSetService } from 'src/app/services/data-set.service';
import { Connection } from 'src/app/models/dataset.model';
import { IpinfoService } from 'src/app/services/ipinfo.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss']
})

export class ConnectionComponent implements OnInit {

  activeConnection:Connection;

  ConnectionScore:number;
  loadCoord:boolean;
  isLoad:boolean;
  analysisDone:boolean;
  
  ipSubscription: Subscription;
  analysisSubscription: Subscription;

  accessToken:string = 'pk.eyJ1Ijoicmh6IiwiYSI6ImNra2N3enRobjA0bjkycW1paWhpbXJlbWMifQ.mjRBMWRc8_i_7k8M8gQgsQ';
  map: mapbox.Map;
  style:string = 'mapbox://styles/mapbox/dark-v10';

  constructor(private http:HttpClient,
              private device: DeviceDetectorService,
              private dataService: DataSetService,
              private ipInfo: IpinfoService) {
   }

  ngOnInit(): void {

    this.activeConnection = new Connection();
    this.activeConnection.setLanguage(navigator.language);
    this.loadCoord = false;
    this.isLoad = false;
    this.analysisDone = false;

    setTimeout(() => {
      this.isItLoad();
    }, 10000);
    
    this.analysisSubscription = this.dataService.analysisDoneSubject.subscribe((val)=>{
      this.analysisDone = val;
    });
    this.dataService.emitAnalysisDoneSubject();

    this.ipSubscription = this.http.get("https://api.ipify.org/?format=json").subscribe((val)=>{
      this.activeConnection.ip = val["ip"];
      this.http.get("https://ipapi.co/"+val['ip']+"/json/").subscribe((val)=>{
        this.activeConnection.city = val["city"].replace(/\d+/,"").trim();
        this.activeConnection.country = val["country_name"].replace(/\d+/,"").trim();
        this.activeConnection.countryCode = val["country_code"].replace(/\d+/,"").trim();
        this.activeConnection.lat = val["latitude"];
        this.activeConnection.lon = val["longitude"];
        this.map = new mapbox.Map({
          interactive: false,
          localIdeographFontFamily: "'Fira Code', monospace",
          attributionControl: false,
          accessToken: this.accessToken,
          container: 'map',
          style: this.style,
          zoom: 13,
          center: [this.activeConnection.lon, this.activeConnection.lat]
        });
        var marker = new mapbox.Marker({
          color: "#ff7900",
          scale: 0.5
        })
          .setLngLat([this.activeConnection.lon, this.activeConnection.lat])
          .addTo(this.map);
        this.loadCoord = true;
        this.activeConnection.isp = val['org'];
        this.activeConnection.as = val['asn'];
      });
    });
    //console.log("get Device infos");
    let deviceInfo = this.device.getDeviceInfo();
    this.activeConnection.os = deviceInfo.os;
    this.activeConnection.osVersion = deviceInfo.os_version;
    this.activeConnection.browser = deviceInfo.browser;
    this.activeConnection.browserVersion = deviceInfo.browser_version;
    this.activeConnection.userAgent = deviceInfo.userAgent;
    this.activeConnection.device = deviceInfo.device;
    this.activeConnection.deviceType = deviceInfo.deviceType;
    this.activeConnection.screenOrientation = deviceInfo.orientation;
    this.activeConnection.screenSize = screen.availWidth+"X"+screen.availHeight;
    this.waitLoad();
  }

  ngOnDestroy():void {
    this.ipSubscription.unsubscribe();
    this.analysisSubscription.unsubscribe();
  }

  waitLoad() {
    if(this.loadCoord) {
      this.isLoad = true;
      this.dataService.checkConnection(this.activeConnection).then(()=>{
        this.ipInfo.setConnection(this.activeConnection);
      });
    } else {
      setTimeout(() => {
        this.waitLoad();
      }, 200);
    }
  }

  isItLoad() {
    if(!this.isLoad) {
      //console.log("bug sur l'ip");
      this.ipSubscription.unsubscribe();
      this.analysisSubscription.unsubscribe();
      this.ngOnInit();
    }
  }

}
