import { Component, OnInit } from '@angular/core';
import { Categorie, Competence, Theme } from '../../models/competence.model';
import { ExempleService } from '../../services/exemple.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  age:number;

  themeList:Array<Theme>;

  clicked:boolean;
  elClicked:any;
  clickId:Array<number>;

  constructor(private exempleService: ExempleService) {
    let birthday = new Date(1990,3,28);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    this.age = Math.abs(ageDate.getUTCFullYear() - 1970);
    this.clickId = [0,0,-1];

    this.loadCat();
   }

  ngOnInit(): void {
    this.loadCat();
  }

  ngOnDestroy() {
  }

  loadCat(){
    this.themeList = [];
    let theme = ['Cyber Security','Coding','Data','Computing'];
    let cat = [
      new Categorie('Web programming','Coding',[]),
      new Categorie('DataBase','Data',[]),
      new Categorie('Network','Computing',[]),
      new Categorie('Security','Cyber Security',[]),
    ];
    let comp = [
      new Competence('HTML',['Web programming'],5,"html"),
      new Competence('SASS / CSS',['Web programming'],5,"html"),
      new Competence('Javascript / Jquery',['Web programming'],5,"html"),
      new Competence('PHP / Symphony',['Web programming'],3,"web"),
      new Competence('Angular',['Web programming'],4,"web"),
      new Competence('React',['Web programming'],3,"web"),
      new Competence('SQL',['DataBase'],3,"database"),
      new Competence('MySQL',['DataBase'],4,"database"),
      new Competence('FireBase',['DataBase','Web programming'],4,"database"),
      new Competence('Python',['DataBase'],4,"database"),
      new Competence('Data Mining',['DataBase'],3,"database"),
      new Competence('Data Science',['DataBase'],2,"frame"),
      new Competence('Data Analisys',['DataBase'],3,"frame"),
      new Competence('Microsoft Excel',['DataBase'],3,"database"),
      new Competence('Network Configuration',['Network'],2,"network"),
      new Competence('Network Maintenance',['Network'],2,"network"),
      new Competence('Network frame',['Network','Security'],4,"frame"),
      new Competence('Whireshark',['Network','Security'],3,"frame"),
      new Competence('Operating system',['Network'],3,"network"),
      new Competence('TOR network',['Security'],4,"hack"),
      new Competence('Kali Linux',['Security'],3,"hack"),
      new Competence('Threat intellingence tools',['Security'],5,"threat"),
      new Competence('Cyber threat analisys',['Security'],4,"frame"),
      new Competence('Phishing analisys',['Security'],4,"threat"),
      new Competence('Typescript',['Web programming'],4,"web"),
    ];

    for (let x = 0; x < theme.length; x++) {
      let tempCat=[];
      for (let i = 0; i < cat.length; i++) {
        if(cat[i].theme == theme[x]) {
          for (let j = 0; j < comp.length; j++) {
            if(comp[j].categorie.indexOf(cat[i].name)!=-1) {
              cat[i].addComp(comp[j]);
            }
          }
          tempCat.push(cat[i]);
        }
      }
      this.themeList.push(new Theme(theme[x],tempCat));
    }

    let find = false;
    for (let i = 0; i < this.themeList.length; i++) {
      if(find) break;
      for (let j = 0; j < this.themeList[i].categorie.length; j++) {
        if(find) break;
        for (let k = 0; k < this.themeList[i].categorie[j].compList.length; k++) {
          if(find) break;
          if(this.themeList[i].categorie[j].compList[k].exemple == "html") {
            this.clickDetails(this.themeList[i].categorie[j].compList[k],[i,j,k]);
            find = true;
          }
        }
        
      }
      
    }
  }

  clickDetails(comp:Competence, sheme:Array<number>) {
    this.themeList[this.clickId[0]].isActive = false;
    this.themeList[this.clickId[0]].categorie[this.clickId[1]].isActive = false;
    if(this.clickId[2]!=-1) this.themeList[this.clickId[0]].categorie[this.clickId[1]].compList[this.clickId[2]].isActive = false;
    this.clickId = sheme;
    //console.log(sheme)
    this.themeList[this.clickId[0]].isActive = true;
    this.themeList[this.clickId[0]].categorie[this.clickId[1]].isActive = true;
    this.themeList[this.clickId[0]].categorie[this.clickId[1]].compList[this.clickId[2]].isActive = true;
    this.exempleService.setActiveExemple(comp.exemple);
  }

  mousewheel(event:any) {
    document.getElementsByClassName('experience')[0].scrollLeft += (event.deltaY);
    event.preventDefault();
  }

  mailto() {
    alert("email")
  }

}
