<div class="container">
    <div class="col-span-2 center border-b">Your Device infos</div>
    <div>Your IP:</div><div id="clientIp" *ngIf="isLoad; else elseLoad">{{this.activeConnection.ip}}</div>
    <div>Your provider:</div><div id="clientIsp" *ngIf="isLoad; else elseLoad">{{this.activeConnection.isp}}</div>
    <div>Autonomous System:</div><div id="clientAs" *ngIf="isLoad; else elseLoad">{{ this.activeConnection.as }}</div>
    <div>Organisation:</div><div id="clientOrg" *ngIf="isLoad; else elseLoad">{{this.activeConnection.org ? this.activeConnection.org : "Unknown"}}</div>
    <div class="col-span-2 empty"></div>
    <div>City:</div><div id="clientCity" *ngIf="isLoad; else elseLoad">{{this.activeConnection.city}}</div>
    <div>Country:</div><div id="clientCountry" *ngIf="isLoad; else elseLoad">{{this.activeConnection.country}} {{this.activeConnection.countryCode}}</div>
    <div id="map" class="map col-span-2"></div>
    <div class="col-span-2 empty"></div>
    <div>OS:</div><div id="deviceOs" *ngIf="isLoad; else elseLoad">{{this.activeConnection.os}}</div>
    <div>Version:</div><div id="deviceOsVersion" *ngIf="isLoad; else elseLoad">{{this.activeConnection.osVersion}}</div>
    <div>Browser:</div><div id="deviceBrowser" *ngIf="isLoad; else elseLoad">{{this.activeConnection.browser}}</div>
    <div>Version:</div><div id="deviceBrowserVersion" *ngIf="isLoad; else elseLoad">{{this.activeConnection.browserVersion}}</div>
    <div>Laguage:</div><div id="deviceBrowserVersion" *ngIf="isLoad; else elseLoad">{{this.activeConnection.language}}</div>
    <div id="deviceAgent" class="col-span-2" *ngIf="isLoad; else elseLoad2">{{this.activeConnection.userAgent}}</div>
    <div class="col-span-2 empty"></div>
    <div>Device:</div><div id="device" *ngIf="isLoad; else elseLoad">{{this.activeConnection.device}}</div>
    <div>Type:</div><div id="deviceType" *ngIf="isLoad; else elseLoad">{{this.activeConnection.deviceType}}</div>
    <div>Screen:</div><div id="screenOrientation" *ngIf="isLoad; else elseLoad">{{this.activeConnection.screenOrientation}}</div>
    <div>Size:</div><div id="screenSize" *ngIf="isLoad; else elseLoad">{{this.activeConnection.screenSize}}</div>
    <div class="col-span-2 empty"></div>
    <div class="col-span-2 center border-b">Threat Analysis Result:</div>
    <ng-container *ngIf="analysisDone; else elseLoad2">
        <div class="col-span-2 center color-1" *ngIf="this.activeConnection.score<-300">All clean !</div>
        <div class="col-span-2 center color-1" *ngIf="this.activeConnection.score<500 && this.activeConnection.score>=-300">Potentially a threat !</div>
        <div class="col-span-2 center color-1" *ngIf="this.activeConnection.score>=500">Threat detected !</div>
    </ng-container>
    <ng-template #elseLoad><div class="loading"></div></ng-template>
    <ng-template #elseLoad2><div class="loading col-span-2"></div></ng-template>
</div>
