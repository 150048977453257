<div class="container">
    <div id="HTML" *ngIf="activeContent=='html'">
        <div class="doctypeTag"></div>
        <div class="htmlTag"></div>
        <div class="headTag"></div>
        <div class="metaTag"></div>
        <div class="titleTag"></div>
        <div class="linkTag"></div>
        <div class="scriptTag"></div>
        <div class="headTag close"></div>
        <div class="bodyTag"></div>
        <div class="containerTag"></div>
        <div class="rowTag"></div>
        <div class="colTag col1"><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;">{{ htmlTxt[0] }}</span></div><div class="colTag col1 close"></div>
        <div class="colTag col2"><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;">{{ htmlTxt[1] }}</span></div><div class="colTag col2 close"></div>
        <div class="colTag col3"><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight: bold;">{{ htmlTxt[2] }}</span></div>
        <div class="breaker">
            <div class="colTag col3 close"></div>
            <div class="rowTag close"></div>
            <div class="containerTag close"></div>
            <div class="bodyTag close"></div>
            <div class="htmlTag close"></div>
        </div>
        <div class="splash">
            <span *ngFor="let i of arrayForLoop"></span>
        </div>
    </div>
    <div id="frame" *ngIf="activeContent=='frame'">
        <div class="table">
            <div class="tr head"><div class="bold td">No.</div><div class="bold td">Time</div><div class="bold td">Source</div><div class="bold td">Destination</div><div class="bold td">Protocol</div></div>
            <ng-container *ngFor="let frame of arrayFrame">
                <div class="tr" (click)="openTr($event)"><div class="td">{{frame.n}}</div><div class="td">{{frame.time}}</div><div class="td">{{frame.source}}</div><div class="td">{{frame.destination}}</div><div class="td">{{frame.protocol}}</div></div>
                <div class="hide tr"><div class="td col-span-4 txt">{{ frameTxt[0] }}</div></div>
            </ng-container>
        </div>
        <div class="left-info">{{ frameTxt[1] }}</div>
        <div class="anim">
            <div class="line">
                <div *ngIf="connection">Datas:</div>
                <div [ngClass]="{'data':true, 'ip':true, 'good':ipScoreAnim<-300, 'bad':ipScoreAnim>=500, 'neutral':ipScoreAnim<500 && ipScoreAnim>=-300 }" *ngIf="connection">{{connection.ip}}</div>
                <div [ngClass]="{'data':true, 'city':true, 'hide':!ipScoreAnimEnd, 'good':countryScoreAnim<-300, 'bad':countryScoreAnim>=500, 'neutral':countryScoreAnim<500 && countryScoreAnim>=-300 }" *ngIf="connection">{{connection.city}}</div>
                <div [ngClass]="{'data':true, 'provider':true, 'hide':!countryScoreAnimEnd, 'good':ispScoreAnim<-300, 'bad':ispScoreAnim>=500, 'neutral':ispScoreAnim<500 && ispScoreAnim>=-300 }" *ngIf="connection">{{connection.isp}}</div>
                <div [ngClass]="{'data':true, 'os':true, 'hide':!ispScoreAnimEnd, 'good':osScoreAnim<-300, 'bad':osScoreAnim>=500, 'neutral':osScoreAnim<500 && osScoreAnim>=-300 }" *ngIf="connection">{{connection.os}}</div>
                <div [ngClass]="{'data':true, 'language':true, 'hide':!osScoreAnimEnd, 'good':languageScoreAnim<-300, 'bad':languageScoreAnim>=500, 'neutral':languageScoreAnim<500 && languageScoreAnim>=-300 }" *ngIf="connection">{{connection.language}}</div>
                <div *ngIf="connection">Score:</div>
                <div [ngClass]="{'score':true, 'ip':true}" *ngIf="connection">{{ipScoreAnim}}</div>
                <div [ngClass]="{'score':true, 'city':true, 'hide':!ipScoreAnimEnd}" *ngIf="connection">{{countryScoreAnim}}</div>
                <div [ngClass]="{'score':true, 'provider':true, 'hide':!countryScoreAnimEnd}" *ngIf="connection">{{ispScoreAnim}}</div>
                <div [ngClass]="{'score':true, 'os':true, 'hide':!ispScoreAnimEnd}" *ngIf="connection">{{osScoreAnim}}</div>
                <div [ngClass]="{'score':true, 'language':true, 'hide':!osScoreAnimEnd}" *ngIf="connection">{{languageScoreAnim}}</div>
                <div class="result col-span-6" *ngIf="languageScoreAnimEnd">Ici le score total est de {{ totalScoreAnim }} la connection sera donc considérée comme {{ totalScoreAnim > 500 ? "Suspecte" : totalScoreAnim <= -300 ? "Non suspecte" : "Neutre" }}</div>
            </div>
        </div>
    </div>
    <div id="hack" *ngIf="activeContent=='hack'">
        <div class="tor-logo"><a href="https://www.torproject.org/" target="_blank"></a></div><div class="tor-intro">{{ hackTxt[0] }}</div>
        <div class="col-span-2">{{ hackTxt[1] }}</div>
        <div [ngClass]="{'anim-container':true, 'col-span-2':true, 'hide':hackTxt[2].length>1 }">
            <div [ngClass]="{'computer-logo':true, 'hide':hackTxt[2].length>1 || true}"></div>
            <div class="line" id="computer-line"></div>
            <div class="point" id="computer-point"></div>
            <div [ngClass]="{'server-logo':true, 'hide':hackTxt[2].length>1 || true}"></div>
            <div class="line" id="server-line"></div>
            <div class="point" id="server-point"></div>
            <div [ngClass]="{'wifi-logo':true, 'hide':hackTxt[2].length>1 || true}"></div>
            <div class="line" id="wifi-line"></div>
            <div class="point" id="wifi-point"></div>
            <div [ngClass]="{'kali-logo':true, 'hide':hackTxt[2].length>1 || true}"><a href="https://www.kali.org/" target="_blank"></a></div>
        </div>
        <div class="col-span-2">{{ hackTxt[2] }}</div>
    </div>
    <div id="threatTools" *ngIf="activeContent=='threatTools'">
        <div class="accertify-logo"><a href="https://www.accertify.com//" target="_blank"></a></div><div class="threatMetrix-logo"><a href="https://risk.lexisnexis.com/global/en/products/threatmetrix" target="_blank"></a></div>
        <div class="col-span-2" style="padding: 0.5rem;">{{ threatTxt[0] }}</div>
        <div class="col-span-2" style="padding: 0.5rem;">{{ threatTxt[1] }}</div>
        <div style="padding: 0.5rem;">{{ threatTxt[2] }}</div>
        <ng-container *ngIf="threatTxt[2]">
            <div class="anim" *ngIf="threatTxt[2].length>0" style="padding: 0.5rem;">
                <div class="server">server</div>
                <div class="login">login_page.php</div><div class="bande-login"></div>
                <div class="style">style.css</div><div class="bande-style"></div>
                <div class="sender">sender.php</div><div class="bande-sender"></div>
                <div class="icon">icon.ico</div><div class="bande-icon"></div>
                <div class="datas">datas</div><div class="bande-datas"></div>
                <div class="json-cont"><div class="json">victimes.json</div></div><div class="bande-json"></div>
                <div class="img">img</div><div class="bande-img"></div>
                <div class="logo">logo.png</div><div class="bande-logo"></div>
                <div class="header">header.png</div><div class="bande-header"></div>
            </div>
        </ng-container>
    </div>
    <div id="web" *ngIf="activeContent=='web'">
        <div class="col-span-3">{{webTxt[0]}}</div>
        <div class="openclassroom"><a href="https://openclassrooms.com/" target="_blank"></a></div>
        <div class="studi"><a href="https://www.digital-campus.live/ecole" target="_blank"></a></div>
        <div class="stack"><a href="https://stackoverflow.com/" target="_blank"></a></div>
        <div class="col-span-3">{{webTxt[1]}}</div>
    </div>
    <div id="database" *ngIf="activeContent=='database'">
        <div>{{databaseTxt[0]}}</div>
        <div class="database-example">
            <table>
                <tr><th>id</th><th>name</th><th>length</th><th>from</th><th>time</th><th>count</th></tr>
                <tr *ngFor="let o of dataList;let i = index"><td style="text-align: center;">{{i}}</td><td [ngClass]="{'active':o.active[0]}">{{o.name}}</td><td [ngClass]="{'active':o.active[1]}">{{o.length}}</td><td [ngClass]="{'active':o.active[2]}">{{o.from}}</td><td [ngClass]="{'active':o.active[3]}">{{o.time}}</td><td [ngClass]="{'active':o.active[4]}">{{o.count}}</td></tr>
            </table>
        </div>
        <div>{{databaseTxt[1]}}</div>
    </div>
    <div id="network" *ngIf="activeContent=='network'">
        <div>{{networkTxt[0]}}</div>
        <div class="network-sheme" (window:resize)="traceLines()">
            <div class="col-span-4"></div><div class="router" id="router1"></div><div class="col-span-4"></div>
            <div class="col-span-2"></div><div class="switch" id="switch1"></div><div class="col-span-3"></div><div class="switch" id="switch2"></div><div class="col-span-2"></div>
            <div class="computer" id="computer1"></div><div class="computer" id="computer2"></div><div class="computer" id="computer3"></div>
            <div class="col-span-2"></div>
            <div class="computer" id="computer4"></div><div class="computer" id="computer5"></div><div class="computer" id="computer6"></div><div class="computer" id="computer7"></div>
            <svg class="lines">
                <line id="router-line1" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="router-line2" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line1" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line2" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line3" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line4" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line5" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line6" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
                <line id="switch-line7" x1="0" y1="0" x2="0" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
            </svg>
        </div>
        <div>{{networkTxt[1]}}</div>
    </div>
    <div id="security" *ngIf="activeContent=='security'">
    </div>
</div>
