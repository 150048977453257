import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Connection } from 'src/app/models/dataset.model';
import { DataExemple, Frame } from 'src/app/models/exemple.model';
import { DataSetService } from 'src/app/services/data-set.service';
import { ExempleService } from 'src/app/services/exemple.service';
import { IpinfoService } from 'src/app/services/ipinfo.service';

@Component({
  selector: 'app-exemple',
  templateUrl: './exemple.component.html',
  styleUrls: ['./exemple.component.scss']
})
export class ExempleComponent implements OnInit {

  activeContent:string;
  activeInstance:number;
  activeExempleSubscirption:Subscription;

  htmlContent:string;

  hackTxt:Array<string>;
  frameTxt:Array<string>;
  threatTxt:Array<string>;
  webTxt:Array<string>;
  databaseTxt:Array<string>;
  networkTxt:Array<string>;
  securityTxt:Array<string>;
  htmlTxt:Array<string>;

  hackLoad:boolean;
  frameLoad:boolean;
  threatLoad:boolean;
  webLoad:boolean;
  databaseLoad:boolean;
  networkLoad:boolean;
  securityLoad:boolean;
  htmlLoad:boolean;

  arrayForLoop:Array<number>;
  arrayFrame:Array<Frame>;
  dataList:Array<DataExemple>;

  writeEnd:boolean;
  writeEndSubject:Subject<boolean>;

  connection: Connection;
  connectionSubscription: Subscription;

  osScore:number;
  languageScore:number;
  countryScore:number;
  osScoreAnim:number;
  languageScoreAnim:number;
  countryScoreAnim:number;
  ipScoreAnim:number;
  ispScoreAnim:number;
  totalScoreAnim:number;
  osScoreAnimEnd:Boolean;
  languageScoreAnimEnd:Boolean;
  countryScoreAnimEnd:Boolean;
  ipScoreAnimEnd:Boolean;
  ispScoreAnimEnd:Boolean;
  osScoreSubscription:Subscription;
  languageScoreSubscription:Subscription;
  countryScoreSubscription:Subscription;

  constructor(private ipInfo: IpinfoService, private data: DataSetService, private ExempleService: ExempleService) {
    this.activeContent = "none";
    this.arrayForLoop = Array(10).fill(0);
    this.arrayFrame = [];
    this.dataList = [];
    this.writeEnd = false;
    this.activeInstance = 0;

    this.hackTxt = [];
    this.frameTxt = [];
    this.threatTxt=[];
    this.webTxt=[];
    this.databaseTxt=[];
    this.networkTxt=[];
    this.securityTxt=[];
    this.htmlTxt=[];

    this.hackLoad=false;
    this.frameLoad=false;
    this.threatLoad=false;
    this.webLoad=false;
    this.databaseLoad=false;
    this.networkLoad=false;
    this.securityLoad=false;
    this.htmlLoad=false;

    this.osScoreAnim=0;
    this.languageScoreAnim=0;
    this.countryScoreAnim=0;
    this.ipScoreAnim=0;
    this.ispScoreAnim=0;
    this.osScoreAnimEnd=false;
    this.languageScoreAnimEnd=false;
    this.countryScoreAnimEnd=false;
    this.ipScoreAnimEnd=false;
    this.ispScoreAnimEnd=false;
    this.totalScoreAnim=0;

    this.writeEndSubject = new Subject<boolean>();

    let startNumber  = Math.floor(Math.random() * 999) + 1;
    for (let i = 0; i < 5; i++) {
      this.arrayFrame.push(new Frame(i+startNumber));
    }
    
    let listname = "Donec congue lobortis tempus lorem dolor luctus enim nec tincidunt odio tortor non arcu".split(' ');
    for (let i = 0; i < listname.length; i++) {
      this.dataList.push(new DataExemple(listname[i]));
    }
   }

  ngOnInit(): void {
    this.connectionSubscription = this.ipInfo.connSubject.subscribe((val)=>{
      this.connection = val;
    })
    this.ipInfo.emitConnection();

    this.osScoreSubscription = this.data.osScoreSubject.subscribe((val) => {
      this.osScore = val;
    });
    this.data.emitOsScoreSubject();

    this.languageScoreSubscription = this.data.langScoreSubject.subscribe((val) => {
      this.languageScore = val;
    });
    this.data.emitLangScoreSubject();

    this.countryScoreSubscription = this.data.countryScoreSubject.subscribe((val) => {
      this.countryScore = val;
    });
    this.data.emitCountryScoreSubject();

    this.activeExempleSubscirption = this.ExempleService.activeExempleSubject.subscribe((val)=>{
      //this.security();
      switch(val){
        case "html":
          if(this.activeContent!="html") {
            this.activeInstance++;
            this.html();
            //console.log("html click")
          }
          break;
        case "frame":
          if(this.activeContent!="frame") {
            this.activeInstance++;
            this.frame();
            //console.log("frame click")
          }
          break;
        case "threat":
          if(this.activeContent!="threatTools") {
            this.activeInstance++;
            this.threatTools();
            //console.log("threat click")
          }
          break;
        case "hack":
          if(this.activeContent!="hack") {
            this.activeInstance++;
            this.hack();
            //console.log("hack click")
          }
          break;
        case "security":
          if(this.activeContent!="security") {
            this.activeInstance++;
            this.security();
            //console.log("security click")
          }
          break;
        case "web":
          if(this.activeContent!="web") {
            this.activeInstance++;
            this.web();
            //console.log("web click")
          }
          break;
        case "database":
          if(this.activeContent!="database") {
            this.activeInstance++;
            this.database();
            //console.log("database click")
          }
          break;
        case "network":
          if(this.activeContent!="network") {
            this.activeInstance++;
            this.network();
            //console.log("network click")
          }
          break;
      }
    });
    this.ExempleService.emitActiveExemple();
  }

  emitWriteEnd() {
    this.writeEndSubject.next(this.writeEnd);
  }

  frame() {
    this.activeContent = "frame";
    this.frameTxt = [
      "Grâce aux datas collectées lors de l'échange de trames, j’ai pu créer une base de données regroupant chaque information non personnelle d’une connexion client sur un site internet.",
    ];
    let txt = ["Dans le panneau de gauche “Your Device info” vous retrouvez un exemple d'algorithme de data science basé sur les informations collectées par un site lors de la connexion du client. Ce panneau est basé sur un algorithme d’apprentissage supervisé (ici il n’enregistre aucune donnée) qui permet d’associer un score à votre connexion et rendre le résultat “Threat Analysis Result”"];
    
    if(!this.frameLoad) {
      this.write("frame0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe()
          this.frameLoad = true;
        }
      });
    } else {
      this.frameTxt.push(txt[0]);
    }

    this.startAnimScore();
  }

  startAnimScore() {
    if(this.activeContent!="frame") return;
    if(this.connection) {
      this.ipScoreAnim = 0;
      this.ispScoreAnim = 0;
      this.osScoreAnim = 0;
      this.languageScoreAnim = 0;
      this.countryScoreAnim = 0;
      this.ipScoreAnimEnd = false;
      this.ispScoreAnimEnd = false;
      this.osScoreAnimEnd = false;
      this.languageScoreAnimEnd = false;
      this.countryScoreAnimEnd = false;
      this.animScore(Math.floor(Math.random() * 50) + 15,0,1);
    } else {
      setTimeout(() => {
        this.startAnimScore();
      }, 500);
    }
  }

  animScore(loop:number, ind:number, tour:number) {
    if(this.activeContent!="frame") return;
    if(ind < loop && tour==1) {
      this.getAnimScore().then((val:number)=>{
        this.ipScoreAnim = val;
        this.animScore(loop,ind+1,tour);
      });
    } else if(ind < loop && tour==2) {
      this.getAnimScore().then((val:number)=>{
        this.countryScoreAnim = val;
        this.animScore(loop,ind+1,tour);
      });
    } else if(ind < loop && tour==3) {
      this.getAnimScore().then((val:number)=>{
        this.ispScoreAnim = val;
        this.animScore(loop,ind+1,tour);
      });
    } else if(ind < loop && tour==4) {
      this.getAnimScore().then((val:number)=>{
        this.osScoreAnim = val;
        this.animScore(loop,ind+1,tour);
      });
    } else if(ind < loop && tour==5) {
      this.getAnimScore().then((val:number)=>{
        this.languageScoreAnim = val;
        this.animScore(loop,ind+1,tour);
      });
    } else if(tour<6) {
      switch(tour){
        case 1:
          this.ipScoreAnimEnd = true;
          break;
        case 2:
          this.countryScoreAnim = this.countryScore;
          this.countryScoreAnimEnd = true;
          break;
        case 3:
          this.ispScoreAnimEnd = true;
          break;
        case 4:
          this.osScoreAnim = this.osScore;
          this.osScoreAnimEnd = true;
          break;
        case 5:
          this.languageScoreAnim = this.languageScore;
          this.languageScoreAnimEnd = true;
          break;
      }
      loop = Math.floor(Math.random() * 35) + 15;
      ind = 0;
      tour++;
      this.animScore(loop,ind,tour);
    } else {
      this.totalScoreAnim = Math.floor((this.ipScoreAnim + this.countryScoreAnim + this.ispScoreAnim + this.osScoreAnim + this.languageScoreAnim)/5)
    }
  }

  getAnimScore() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let v = Math.random() >= 0.55 ? Math.random() : Math.random()*-1;
        v = Math.floor(v*1000);
        resolve(v);
      }, Math.random()*200);
    });
  }

  hack() {
    let initInstance = this.activeInstance;
    this.activeContent = "hack";
    this.hackTxt = ["","",""];
    let txt=["J'ai commencé à m'intéresser au réseau Tor en 2008, par simple curiosité et par envie d’apprendre de nouvelles choses."];
    txt.push("Pour cela je me suis formé sur le site du navigateur Tor et sur des forums classiques comme Stackoverflow, Git ou Openclassroom. Ainsi j’ai pu apprendre les notions de la confidentialité et de la sécurité sur le web.");
    txt.push("Dans cette démarche d’exploration du réseau Tor et de la sécurité informatique j’ai appris à utiliser le système d’exploitation linux et plus particulièrement la distribution Kali. J’ai donc pu tester sur mon propre réseau, serveur et ordinateur, plusieurs outils intégrés dans Kali permettant de pénétrer un réseau wifi, un serveur de données etc...");
    
    if(!this.hackLoad) {
      this.write("hack0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe();
          if(initInstance==this.activeInstance) {
            this.write("hack1",txt[1],"",0,this.activeInstance);
            let sub2 = this.writeEndSubject.subscribe((val)=>{
              if(val) {
                if(initInstance==this.activeInstance) {
                  this.write("hack2",txt[2],"",0,this.activeInstance);
                  let sub3 = this.writeEndSubject.subscribe((val)=>{
                    if(val) {
                      this.hackLoad = true;
                      sub3.unsubscribe();
                    }
                  });
                }
                sub2.unsubscribe();
              }
            });
          }
        }
      });
    } else {
      this.hackTxt=[];
      for (let i = 0; i < txt.length; i++) {
        this.hackTxt.push(txt[i])
      }
    }
  }

  threatTools() {
    let initInstance = this.activeInstance;
    this.activeContent = "threatTools";
    this.threatTxt=["Dans le cadre de mon précédent emploi chez Expedia, j'ai été amené a utiliser quotidiennement des outils de threat intelligents tels que ThreatMetrix et Accertify."];
    let txt= [
      "J’ai pu développer des algorithmes d’analyse des données fournies par ces logiciels afin de vérifier la pertinence des règles mises en place par nos équipes et plus spécifiquement celles concernant le phishing.",
      "J’ai aussi été amené à pénétrer l'arborescence du serveur web hébergeant les pages de phishing afin d’essayer de trouver le fichier contenant la liste des victimes.",
    ];
    if(!this.threatLoad) {
      this.write("threat0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe()
          if(initInstance==this.activeInstance) {
            this.write("threat1",txt[1],"",0,this.activeInstance);
            let sub2 = this.writeEndSubject.subscribe((val)=>{
              sub2.unsubscribe()
              this.threatLoad = true;
            })
          }
        }
      });
    } else {
      for (let i = 0; i < txt.length; i++) {
        this.threatTxt.push(txt[i])
      }
    }
  }

  web() {
    let initInstance = this.activeInstance;
    this.activeContent = "web";
    this.webTxt = [];
    let txt = [];
    txt.push("Au travers de cours en ligne et pendant ma formation de Développeur Full Stack Angular. J’ai appris à maîtriser différents langages web, comme PHP, ReactJs ou Angular.");
    txt.push("Affectionnant particulièrement Angular, j’ai choisi de me spécialiser dans ce langage. D’ailleurs ce site est développé en Angular et utilise des APIs pour le panneau de gauche 'Your Device infos' ainsi que celui de droite 'Personal Data Inspector'. L'avantage d’Angular est de pouvoir gérer le Front et le Back avec un seul langage, le Javascript.");
    
    if(!this.webLoad) {
      this.write("web0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe()
          if(initInstance==this.activeInstance) {
            this.write("web1",txt[1],"",0,this.activeInstance);
            let sub2 = this.writeEndSubject.subscribe((val)=>{
              this.webLoad = true;
              sub2.unsubscribe();
            });
          }
        }
      });
    } else {
      for (let i = 0; i < txt.length; i++) {
        this.webTxt.push(txt[i])
      }
    }
  }

  security() {
    this.activeContent = "security";
    this.securityTxt = [""];
    let txt = [];
  }

  network() {
    let initInstance = this.activeInstance;
    this.activeContent = "network";
    this.networkTxt = [];
    let txt = [];
    txt.push("Durant ma formation en IUT réseaux et télécommunications, j’ai été amené à configurer, dimensionner et maintenir des réseaux. J’ai donc étudié les différentes couches du modèle OSI et TCP/IP, le routing, l'adressage et les normes CISCO (CCNA). J’ai aussi été amené à installer, maintenir et dépanner différents OS (Linux et Windows).");
    txt.push("Pendant mon emploi en tant qu’analyste fraudes et sécurité j’ai pu appliquer mes connaissances, afin de mieux identifier les potentielles connexions pirates et plus précisément celles de comptes utilisateurs victimes de phishing.");

    if(!this.networkLoad) {
      this.write("network0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe()
          if(initInstance==this.activeInstance) {
            this.write("network1",txt[1],"",0,this.activeInstance);
            let sub2 = this.writeEndSubject.subscribe((val)=>{
              this.networkLoad = true;
              sub2.unsubscribe();
            });
          }
        }
      });
    } else {
      for (let i = 0; i < txt.length; i++) {
        this.networkTxt.push(txt[i])
      }
    }

    //trace lines
    this.traceLines();
  }

  traceLines() {
    class Position {
      x:number|boolean;
      y:number|boolean;

      constructor(x:number|boolean,y:number|boolean) {
        this.x = x;
        this.y = y;
      }
    }

    function getXY(id:string) {
      let el = <HTMLElement>document.getElementById(id);
      if(el) {
        let x=(el.offsetLeft - el.scrollLeft + el.clientLeft);
        x+=(el.offsetWidth / 2)
        let y=(el.offsetTop - el.scrollTop + el.clientTop);
        y+=(el.offsetHeight / 2)
        return new Position(x,y);
      } else return new Position(false,false);
    }

    function setLine(p1:Position,p2:Position,id:string) {
      let el = <HTMLElement>document.getElementById(id);
      el.setAttribute('x1',p1.x+'px');
      el.setAttribute('y1',p1.y+'px');
      el.setAttribute('x2',p2.x+'px');
      el.setAttribute('y2',p2.y+'px');
    }

    if(getXY("router1").x) {
      let pos1 = getXY("router1");
      let pos2 = getXY("switch1");
      setLine(pos1,pos2,"router-line1");
      pos1 = getXY("router1");
      pos2 = getXY("switch2");
      setLine(pos1,pos2,"router-line2");
      for (let i = 1; i < 8; i++) {
        if(i<4) pos1 = getXY("switch1");
        else pos1 = getXY("switch2");
        pos2 = getXY("computer"+i);
        setLine(pos1,pos2,"switch-line"+i);
      }
    } else {
      setTimeout(() => {
        this.traceLines();
      }, 500);
    }
  }

  database() {
    let initInstance = this.activeInstance;
    this.activeContent = "database";
    this.databaseTxt = [];
    let txt = [];
    txt.push("Par curiosité et par envie d’apprendre la Data Science, je me suis intéressé au Data Mining (transformer une base de données en informations utiles). Ayant appris lors de mon cursus scolaire et via des cours en ligne la gestion de base de données SQL, MySQL, firebase et MongoDB (apprentissage en cours), j’ai pu appliquer ces connaissances lors de différents projets chez Expedia.");
    txt.push("J’ai choisi d’apprendre et d'utiliser le langage Python pour appliquer ces algorithmes de Datas Science, car Python offre un grand nombre de bibliothèques orientées Data et est surtout rapide pour effectuer de gros calculs.");
    txt.push("Dans le panneau de droite “Personal Data Inspector”, j’ai créé une API en python qui grâce à une analyse préalable de l’adresse mail entrée arrive a recherché sur le web des informations vous concernant (voir compétence algorithme pour plus de détails).");
    
    if(!this.databaseLoad) {
      this.write("database0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe()
          if(initInstance==this.activeInstance) {
            this.write("database1",txt[1],"",0,this.activeInstance);
            let sub2 = this.writeEndSubject.subscribe((val)=>{
              if(val) {
                sub2.unsubscribe()
                if(initInstance==this.activeInstance) {
                  this.write("database2",txt[2],"",0,this.activeInstance);
                  let sub3 = this.writeEndSubject.subscribe((val)=>{
                    this.databaseLoad = true;
                    sub3.unsubscribe();
                  });
                }
              }
            });
          }
        }
      });
    } else {
      for (let i = 0; i < txt.length; i++) {
        this.databaseTxt.push(txt[i])
      }
    }

    //do focntion to anime the databse
    this.animData(Math.floor(Math.random() * this.dataList.length),Math.floor(Math.random() * 5));
  }
  
  animData(line:number,column:number) {
    if(this.activeContent=="database") {
      this.dataList[line].active[column]=false;
      if(column>=5) {
        line++;
        column=0;
      }
      this.dataList[line].active[column]=true;
      setTimeout(() => {
        this.dataList[line].active[column]=false;
      }, 1500);
      setTimeout(() => {
        let l = Math.floor(Math.random() * this.dataList.length);
        let c = Math.floor(Math.random() * 5);
        this.animData(l,c);
      }, 500);
    } else {
      return;
    }
  }

  html() {
    this.activeContent = "html";
    this.htmlTxt=[];
    let initInstance=this.activeInstance;
    let txt = ["Ce site est codé en HTML SCSS et ANGULAR. Toutes les animations du site sont effectuées en CSS."];
    txt.push("Dans le panneau de gauche vous retrouverez une analyse de votre connexion et dans le panneau de droite un algorithme qui va chercher des informations personnelles vous concernant.");
    txt.push("En cliquant sur mes différentes compétences, vous retrouverez dans ce panneau une rapide description de celles-ci.");

    if(!this.htmlLoad) {
      this.write("html0",txt[0],"",0,this.activeInstance);
      let sub = this.writeEndSubject.subscribe((val)=>{
        if(val) {
          sub.unsubscribe()
          if(initInstance==this.activeInstance) {
            this.write("html1",txt[1],"",0,this.activeInstance);
            let sub2 = this.writeEndSubject.subscribe((val)=>{
              if(val) {
                sub2.unsubscribe();
                if(initInstance==this.activeInstance) {
                  this.write("html2",txt[2],"",0,this.activeInstance);
                  let sub3 = this.writeEndSubject.subscribe((val)=>{
                    this.htmlLoad = true;
                    sub3.unsubscribe();
                  });
                }
              }
            });
          }
        }
      });
    } else {
      for (let i = 0; i < txt.length; i++) {
        this.htmlTxt.push(txt[i])
      }
    }
  }

  write(varTo:string,str:string,val:string,ind:number,inst:number) {
    //console.log(this.activeInstance+" "+inst);
    if(ind<str.length) {
      if(ind==0) {
        val="";
        this.writeEnd=false;
        this.emitWriteEnd();
      }
      let time = Math.random() * 80;
      setTimeout(() => {
        val+=str[ind];
        if(varTo=="html0" && inst == this.activeInstance) {
          this.htmlTxt[0]=val;
          if(this.activeContent!="html") {
            this.writeEnd = true;
            this.emitWriteEnd();
            return;
          }
        } else if(varTo=="html1" && inst == this.activeInstance) {
          this.htmlTxt[1]=val;
          if(this.activeContent!="html") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="html2" && inst == this.activeInstance) {
          this.htmlTxt[2]=val;
          if(this.activeContent!="html") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="hack0" && inst == this.activeInstance) {
          this.hackTxt[0]=val;
          if(this.activeContent!="hack") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="hack1" && inst == this.activeInstance) {
          this.hackTxt[1]=val;
          if(this.activeContent!="hack") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="hack2" && inst == this.activeInstance) {
          this.hackTxt[2]=val;
          if(this.activeContent!="hack") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="frame0" && inst == this.activeInstance) {
          this.frameTxt[1]=val;
          if(this.activeContent!="frame") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="threat0" && inst == this.activeInstance) {
          this.threatTxt[1]=val;
          if(this.activeContent!="threatTools") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="threat1" && inst == this.activeInstance) {
          this.threatTxt[2]=val;
          if(this.activeContent!="threatTools") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="web0" && inst == this.activeInstance) {
          this.webTxt[0]=val;
          if(this.activeContent!="web") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="web1" && inst == this.activeInstance) {
          this.webTxt[1]=val;
          if(this.activeContent!="web") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="web2" && inst == this.activeInstance) {
          this.webTxt[2]=val;
          if(this.activeContent!="web") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="database0" && inst == this.activeInstance) {
          this.databaseTxt[0]=val;
          if(this.activeContent!="database") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="database1" && inst == this.activeInstance) {
          this.databaseTxt[1]=val;
          if(this.activeContent!="database") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="database2" && inst == this.activeInstance) {
          this.databaseTxt[2]=val;
          if(this.activeContent!="database") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="network0" && inst == this.activeInstance) {
          this.networkTxt[0]=val;
          if(this.activeContent!="network") {
            this.writeEnd = true;
            return;
          }
        } else if(varTo=="network1" && inst == this.activeInstance) {
          this.networkTxt[1]=val;
          if(this.activeContent!="network") {
            this.writeEnd = true;
            return;
          }
        } else if(this.activeInstance != inst) {
          //console.log("Wrong Instance for "+varTo+" "+this.activeInstance+" "+inst);
          this.writeEnd=true;
          return;
        }
        this.write(varTo,str,val,ind+1,inst);
      }, time);
    } else {
      this.writeEnd=true;
      this.emitWriteEnd();
    }
  }

  openTr(event:any) {
    let el = event.target || event.srcElement || event.currentTarget;
    let isActive= false;
    if(el.parentElement.nextSibling.className.search("active")!=-1) {
      isActive = true;
    }
    let active = document.querySelectorAll('.tr.active');
    for (let i = 0; i < active.length; i++) {
      active[i].classList.remove('active');
    }
    if(!isActive) el.parentElement.nextSibling.classList.add('active');
  }

}
