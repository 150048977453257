import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Nom, Prenom } from '../models/dataset.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  matchOn:object;
  lastLoop:boolean;
  nbLoop:number;
  isDone:boolean;
  isDoneSubject:Subject<boolean>;

  trustValue:number;
  trustValueSubject:Subject<number>;

  pic:string;
  picSubject:Subject<string>;
  picFound:boolean;
  picFoundSubject:Subject<boolean>;

  address:string;
  addressSubject: Subject<string>;
  addressFound:boolean;
  addressFoundSubject: Subject<boolean>;

  job:string;
  jobSubject: Subject<string>;
  jobFound: boolean;
  jobFoundSubject: Subject<boolean>;

  from:string;
  fromSubject: Subject<string>;
  fromFound: boolean;
  fromFoundSubject: Subject<boolean>;

  company:string;
  companySubject: Subject<string>;
  companyFound: boolean;
  companyFoundSubject: Subject<boolean>;

  phone:string;
  phoneSubject: Subject<string>;
  phoneFound: boolean;
  phoneFoundSubject: Subject<boolean>;

  constructor(private http:HttpClient) {
    this.isDoneSubject = new Subject<boolean>();
    this.picFoundSubject = new Subject<boolean>();
    this.picSubject = new Subject<string>();
    this.addressSubject = new Subject<string>();
    this.addressFoundSubject = new Subject<boolean>();
    this.jobFoundSubject = new Subject<boolean>();
    this.fromFoundSubject = new Subject<boolean>();
    this.companySubject = new Subject<string>();
    this.companyFoundSubject = new Subject<boolean>();
    this.phoneSubject = new Subject<string>();
    this.phoneFoundSubject = new Subject<boolean>();
    this.jobSubject = new Subject<string>();
    this.fromSubject = new Subject<string>();
    this.trustValueSubject = new Subject<number>();
    this.trustValue = 0.5;
    this.picFound = false;
    this.addressFound = false;
    this.jobFound = false;
    this.fromFound = false;
    this.companyFound = false;
    this.phoneFound = false;
    this.isDone = false;
    this.lastLoop = false;
    this.nbLoop = 0;
    this.matchOn = {
      'linkedin':{'nb':0,'haveResult':false,'site':"linkedin.com"},
      '118712':{'nb':0,'haveResult':false,'site':"annuaire.118712.fr"},
      'pagesjaunes':{'nb':0,'haveResult':false,'site':'pagesjaunes.fr'},
      'gendarmerie':{'nb':0,'haveResult':false,'site':'gendarmerie-paca.fr'},
      'facebook':{'nb':0,'haveResult':false,'site':'facebook.com'},
      'instagram':{'nb':0,'haveResult':false,'site':'instagram.com'}
    };
  }

  emitTrust() {
    this.trustValueSubject.next(this.trustValue);
  }

  emitIsDone() {
    this.isDoneSubject.next(this.isDone);
  }

  emitPic() {
    this.picSubject.next(this.pic);
  }

  emitPicFound() {
    this.picFoundSubject.next(this.picFound);
  }

  emitAddress() {
    this.addressSubject.next(this.address);
  }

  emitAddressFound() {
    this.addressFoundSubject.next(this.addressFound);
  }

  emitJob() {
    this.jobSubject.next(this.job);
  }

  emitJobFound() {
    this.jobFoundSubject.next(this.jobFound);
  }

  emitFrom() {
    this.fromSubject.next(this.from);
  }

  emitFromFound() {
    this.fromFoundSubject.next(this.fromFound);
  }

  emitCompany() {
    this.companySubject.next(this.company);
  }

  emitCompanyFound() {
    this.companyFoundSubject.next(this.companyFound);
  }

  emitPhone() {
    this.phoneSubject.next(this.phone);
  }

  emitPhoneFound() {
    this.phoneFoundSubject.next(this.phoneFound);
  }

  searchGoogle(mail:string,prenom:Prenom,nom:Nom,ipCity:string,haveName:boolean,haveSurname:boolean) {
    //console.log(mail+" "+prenom.name+" "+nom.name+" "+ipCity+" "+haveName+" "+haveSurname);
    ipCity = this.getLower(ipCity);
    //ipCity="lyon";

    if(this.nbLoop==0) this.resetValues();

    let url = this.getUrlToSearch(mail,prenom,nom,ipCity,haveName,haveSurname);
    //console.log(url);

    this.http.get(url).subscribe((res)=>{//search mail
      if(res['searchInformation']['totalResults']>0) {
        let match = [];
        for (let i = 0; i < res['items'].length; i++) {
          if(res['items'][i]['displayLink']=="www.facebook.com" && !this.matchOn['facebook']['haveResult']) {
            this.matchOn['facebook']['nb']++;
            let title = this.getLower(res['items'][i]['title']);
            if((title.search(prenom.name+""+nom.name)!=-1 || title.search(nom.name+""+prenom.name)!=-1)) {
              //console.log("match on FB: "+prenom.name+" "+nom.name);
              //console.log(res['items'][i])
              let temp = res['items'][i]['formattedUrl'].split('/');
              match.push({"site":"www.facebook.com","url":res['items'][i]['formattedUrl'],"id":temp[temp.length-1].length > 0 ? temp[temp.length-1] : temp[temp.length-2],"name":prenom.name+"+"+nom.name,"title":title});
            }
          }else if(res['items'][i]['displayLink'].search(/fr\.linkedin\.(com|fr)/gi)!=-1 && !this.matchOn['linkedin']['haveResult']) {
            this.matchOn['linkedin']['nb']++;
            let title = this.getLower(res['items'][i]['title']);
            if((title.search(prenom.name+""+nom.name)!=-1 || title.search(nom.name+""+prenom.name)!=-1) && res['items'][i]['snippet'].search('View the profiles')==-1) {
              //console.log("match on Linkedin: "+prenom.name+" "+nom.name);
              let temp = res['items'][i]['formattedUrl'].split('/');
              match.push({"site":"www.linkedin.com","url":res['items'][i]['formattedUrl'],"id":temp[temp.length-1].length > 0 ? temp[temp.length-1] : temp[temp.length-2],"name":prenom.name.toUpperCase()+"+"+nom.name.toUpperCase(),"title":title});
            }
          } else if(res['items'][i]['displayLink'].search(/annuaire\.118712\.fr/gi)!=-1 && !this.matchOn['118712']['haveResult']) {
            let title = this.getLower(res['items'][i]['title']);
            if((title.search(prenom.name+""+nom.name)!=-1 || title.search(nom.name+""+prenom.name)!=-1)) {
              let temp = res['items'][i]['link'].split('/');
              this.getDistance(temp[temp.length-2],ipCity).subscribe((val)=>{
                //console.log(val)
                let dist = 0
                if(val['status']!="REQUEST_DENIED") dist = val['rows'][0]['elements'][0]["distance"]["value"];
                else if(this.getLower(temp[temp.length-2]).search(ipCity)!=-1) {
                  dist = 0;
                  val['origin_addresses']=[ipCity+" , france"];
                } else {
                  dist = 100000;
                  val['origin_addresses']=["suisse","usa"];
                }
                if(dist < 65000) {
                  //console.log("match on 118712: "+prenom.name+" "+nom.name);
                  match.push({"site":res['items'][i]["displayLink"],"url":res['items'][i]['link'],"id":temp[temp.length-1].length > 0 ? temp[temp.length-1] : temp[temp.length-2],"name":prenom.name.toUpperCase()+"+"+nom.name.toUpperCase(),"title":title});
                }
              });
            }
            this.matchOn['118712']['nb']++;
          } else if(res['items'][i]['displayLink'].search(/gendarmerie-paca/gi)!=-1 && !this.matchOn['gendarmerie']['haveResult']) {
            //console.log("match on gendarmerie-paca:");
            let temp = res['items'][i]['formattedUrl'].split('/');
            let title = this.getLower(res['items'][i]['title']);
            match.push({"site":res['items'][i]["displayLink"],"url":res['items'][i]['formattedUrl'],"id":temp[temp.length-1].length > 0 ? temp[temp.length-1] : temp[temp.length-2],"name":prenom.name.toUpperCase()+"+"+prenom.name.toUpperCase(),"title":title});
            this.matchOn['gendarmerie']['nb']++;
          } else if(res['items'][i]['displayLink'].search(/pagesjaunes/gi)!=-1 && !this.matchOn['pagesjaunes']['haveResult']) {
            let title = this.getLower(res['items'][i]['title']);
            if(title.search(prenom.name+""+nom.name)!=-1 || title.search(nom.name+""+prenom.name)!=-1) {
              //console.log("match on pagesjaunes: "+prenom.name+" "+nom.name);
              let temp = res['items'][i]['formattedUrl'].split('/');
              match.push({"site":res['items'][i]["displayLink"],"url":res['items'][i]['link'],"id":temp[temp.length-1].length > 0 ? temp[temp.length-1] : temp[temp.length-2],"name":prenom.name.toUpperCase()+"+"+nom.name.toUpperCase(),"title":title});
            }
            this.matchOn['pagesjaunes']['nb']++;
          } else if(res['items'][i]['displayLink'].search(/instagram/gi)!=-1 && !this.matchOn['instagram']['haveResult']) {
            let title = this.getLower(res['items'][i]['title']);
            if(title.search(prenom.name+""+nom.name)!=-1 || title.search(nom.name+""+prenom.name)!=-1) {
              //console.log("match on instagram: "+prenom.name+" "+nom.name);
              let temp = res['items'][i]['formattedUrl'].split('/');
              this.matchOn['instagram']['nb']++;
              match.push({"site":res['items'][i]["displayLink"],"url":res['items'][i]['link'],"id":temp[temp.length-1].length > 0 ? temp[temp.length-1] : temp[temp.length-2],"name":prenom.name.toUpperCase()+"+"+nom.name.toUpperCase(),"title":title});
            }
            this.matchOn['instagram']['nb']++;
          } 
        }
        //console.log("On a trouvé ça:");
        //console.log(match);
        this.getDetails(match,0,ipCity,nom,prenom,mail,haveName,haveSurname);

      } else {
        //console.log("no result found");
        this.checkIfDone(mail,prenom,nom,ipCity,haveName,haveSurname);
      }
    });
  }

  getDetails(match:Array<object>,ind:number,ipCity:string,nom:Nom,prenom:Prenom,mail:string,haveName:boolean,haveSurname:boolean) {
    //console.log("getDetails matchOn:");
    if(ind<match.length) {
      //console.log("contacting API site:"+match[ind]['site']+" id="+match[ind]['id']);
      //console.log("in if"+ind);
      if(match[ind]['site'].search('linkedin')!=-1 && (!this.picFound || !this.jobFound || !this.fromFound) && !this.matchOn['linkedin']['haveResult']) {
        //this.http.get('http://metro7.ddns.net:5000/api/v1/linkedin/'+match[ind]['id']).subscribe((res)=>{
        this.http.get('http://ec2-18-217-25-220.us-east-2.compute.amazonaws.com:5000/api/v1/linkedin/'+match[ind]['id']).subscribe((res)=>{
        //this.http.get('http://127.0.0.1:5000/api/v1/linkedin/'+match[ind]['id']).subscribe((res)=>{
          if(res['response_code']==200)
          {
            //console.log("linkedin for: "+match[ind]['id']);
            //console.log(res);
            let geo = '';
            if(res['info'][res['info'][3] ? 3 : res['info'].length-1].search(/France/gi)!=-1) geo = res['info'][res['info'][3] ? 3 : res['info'].length-1].substring(0,res['info'][res['info'][3] ? 3 : res['info'].length-1].search(/France/gi)+6);
            else {
              geo = res['info'][res['info'][3] ? 3 : res['info'].length-1].substring(0,res['info'][res['info'][3] ? 3 : res['info'].length-1].search(/\d/gi));
              if(geo.search(/et p.riph.rie/gi)!=-1) geo = geo.substring(0, geo.search(/et p.riph.rie/gi));
              if(geo.search(/\+ de/gi)!=-1) geo = geo.substring(0, geo.search(/\+ de/gi));
            }

            this.getDistance(geo,ipCity).subscribe((val)=>{
              //console.log(val);
              let dist = 0;
              if(val['status']!="REQUEST_DENIED") dist = val['rows'][0]['elements'][0]["distance"]["value"];
              else if(this.getLower(geo).search(ipCity)!=-1) {
                dist = 0;
                val['origin_addresses']=[ipCity+" , france"];
              } else {
                dist = 100000;
                val['origin_addresses']=["suisse","usa"];
              }
              /* {  "destination_addresses":["Lyon, France"],
                    "origin_addresses":["Villeurbanne, 69100, France"],
                    "rows":[{
                      "elements":[
                        {"distance":{"text":"8.1 km","value":8059},
                        "duration":{"text":"17 min","value":1014},"status":"OK"}]
                      }],
                    "status":"OK"} */
              //console.log(val)
              if(dist < 65000 || this.getLower(val['origin_addresses'][0])=="france") {
                this.calcTrust(haveName,haveSurname,this.getLower(val['origin_addresses'][0])=="france" ? false : true,'linkedin');
                if(!this.picFound && res['pic'].search('data:image')==-1) {
                  this.pic = res['pic'];
                  if(this.getLower(val['origin_addresses'][0])!="france") this.picFound = true;
                  this.emitPic();
                  this.emitPicFound();
                  if(this.getLower(val['origin_addresses'][0])!="france") this.matchOn['linkedin']['haveResult'] = true;
                }
                //do analyse of title [0]:name [1]:.. à un compte [2]:job [3]:from
                if(res['info'][res['info'][3] ? 2 : res['info'].length-2]!="--" && !this.jobFound) {
                  this.job = res['info'][res['info'][3] ? 2 : res['info'].length-2];
                  if(this.getLower(val['origin_addresses'][0])!="france") this.jobFound = true;
                  this.emitJob();
                  this.emitJobFound();
                  if(this.getLower(val['origin_addresses'][0])!="france") this.matchOn['linkedin']['haveResult'] = true;
                }

                if(!this.fromFound) {
                  this.from = res['info'][res['info'][3] ? 3 : res['info'].length-1].substring(0,res['info'][res['info'][3] ? 3 : res['info'].length-1].search(/\d/gi));
                  if(this.from.search(/et p.riph.rie/gi)!=-1) this.from = this.from.substring(0, this.from.search(/et p.riph.rie/gi));
                  if(this.from.search(/\+ de/gi)!=-1) this.from = this.from.substring(0, this.from.search(/\+ de/gi));
                  if(this.getLower(val['origin_addresses'][0])!="france") this.fromFound = true;
                  this.emitFrom();
                  this.emitFromFound();
                  if(this.getLower(val['origin_addresses'][0])!="france") this.matchOn['linkedin']['haveResult'] = true;
                }
              }
              this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
            });
          } else {
            this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
          }
        });
      } else if(match[ind]['site'].search('gendarmerie')!=-1 && !this.matchOn['gendarmerie']['haveResult']) {
        //this.http.get('http://metro7.ddns.net:5000/api/v1/gendarmerie/'+match[ind]['id']+'/'+nom.name).subscribe((res)=>{
        this.http.get('http://ec2-18-217-25-220.us-east-2.compute.amazonaws.com:5000/api/v1/gendarmerie/'+match[ind]['id']+'/'+nom.name).subscribe((res)=>{
        //this.http.get('http://127.0.0.1:5000/api/v1/gendarmerie/'+match[ind]['id']+'/'+nom.name).subscribe((res)=>{
          if(res['response_code']==200)
          {
            this.calcTrust(haveName,haveSurname,true,'gendarmerie');
            //console.log("gendarmerie for: "+match[ind]['id']);
            //console.log(res);
            this.pic = res['pic'];
            this.picFound = true;
            this.emitPic();
            this.emitPicFound();
            this.matchOn['gendarmerie']['haveResult'] = true;
          }
          this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
        });
      } else if(match[ind]['site'].search('118712')!=-1 && (!this.phoneFound || !this.addressFound) && !this.matchOn['118712']['haveResult']) {
        let tempUrl = match[ind]['url'].substr(27).replace(/\//gi,'+');
        //console.log(tempUrl)
        //console.log(match[ind]['url'])
        //this.http.get('http://127.0.0.1:5000/api/v1/118712/'+tempUrl+'/'+nom.name.toUpperCase()+"+"+prenom.name.toUpperCase()).subscribe((res)=>{
        //this.http.get('http://metro7.ddns.net:5000/api/v1/118712/'+tempUrl+'/'+nom.name.toUpperCase()+"+"+prenom.name.toUpperCase()).subscribe((res)=>{
        this.http.get('http://ec2-18-217-25-220.us-east-2.compute.amazonaws.com:5000/api/v1/118712/'+tempUrl+'/'+nom.name.toUpperCase()+"+"+prenom.name.toUpperCase()).subscribe((res)=>{
          if(res['response_code']==200)
          {
            this.calcTrust(haveName,haveSurname,true,'118712');
            //console.log("118712 for: "+match[ind]['id']);
            //console.log(res);
              this.address = res['address'];
              this.addressFound = true;
              this.emitAddress();
              this.emitAddressFound();
              if(res['phone'].search(/(\d{2} ){4}\d{2}/gi)!=-1) {
                this.phone = res['phone'];
                this.phoneFound = true;
                this.emitPhone();
                this.emitPhoneFound();
              }
              this.matchOn['118712']['haveResult'] = true;
          }
          this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
        });
      } else if(match[ind]['site'].search('facebook')!=-1 && (!this.picFound || !this.fromFound || !this.companyFound) && !this.matchOn['facebook']['haveResult']) { 
        //console.log(match[ind])
        try {
          //let url = 'http://127.0.0.1:5000/api/v1/facebook/'+match[ind]['id']+'/';
          //let url = 'http://metro7.ddns.net:5000/api/v1/facebook/'+match[ind]['id']+'/';
          let url = 'http://ec2-18-217-25-220.us-east-2.compute.amazonaws.com:5000/api/v1/facebook/'+match[ind]['id']+'/';
          if(haveSurname) url+=prenom.name;
          else if(haveName) url+=nom.name;
          else url+='none';
          this.http.get(url).subscribe((res)=>{
            if(res['response_code']==200)
            {
              this.getDistance(res['from'],ipCity).subscribe((val)=>{
                //console.log("facebook for: "+match[ind]['id']);
                let dist = 0
                if(val['status']!="REQUEST_DENIED") dist = val['rows'][0]['elements'][0]["distance"]["value"];
                else if(this.getLower(res['from']).search(ipCity)!=-1) {
                  dist = 0;
                  val['origin_addresses']=[ipCity+" , france"];
                } else {
                  dist = 100000;
                  val['origin_addresses']=["suisse","usa"];
                }
                //console.log(res);
                let iFound = false;
                if(dist < 65000) {
                  if(res['pic']!='none' && (res['from']!='none' || !this.picFound)) {
                    this.pic = res['pic'];
                    this.picFound = true;
                    this.emitPic();
                    this.emitPicFound();
                    iFound = true;
                  }
                  if(res['from']!='none' && !this.fromFound) {
                    this.from = res['from'];
                    this.fromFound = true;
                    this.emitFrom();
                    this.emitFromFound();
                    iFound = true;
                  }
                  if(res['company']!='none' && !this.companyFound) {
                    this.company = res['company'];
                    this.companyFound = true;
                    this.emitCompany();
                    this.emitCompanyFound();
                    iFound = true;
                  }
                }
                if(iFound) {
                  this.matchOn['facebook']['haveResult'] = true;
                  if(dist < 65000) this.calcTrust(haveName,haveSurname,true,'facebook');
                  else this.calcTrust(haveName,haveSurname,false,'facebook');
                }
                this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
              });
            } else {
              this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
            }
          });
        } catch(e) {
          console.warn(e)
        }
      } else if(match[ind]['site'].search('pagesjaunes')!=-1 && (!this.phoneFound || !this.addressFound) && !this.matchOn['pagesjaunes']['haveResult']) { 
        let tempUrl = match[ind]['url'].substr(27).replace(/\//gi,'+');
        //console.log(tempUrl)
        //console.log(match[ind]['url'])
        //this.http.get('http://127.0.0.1:5000/api/v1/pages/'+tempUrl+'/'+prenom.name.toUpperCase()+"+"+nom.name.toUpperCase()).subscribe((res)=>{
        //this.http.get('http://metro7.ddns.net:5000/api/v1/pages/'+tempUrl+'/'+prenom.name.toUpperCase()+"+"+nom.name.toUpperCase()).subscribe((res)=>{
        this.http.get('http://ec2-18-217-25-220.us-east-2.compute.amazonaws.com:5000/api/v1/pages/'+tempUrl+'/'+prenom.name.toUpperCase()+"+"+nom.name.toUpperCase()).subscribe((res)=>{
          if(res['response_code']==200)
          {
            //console.log("pagesJaunes for: "+match[ind]['id']);
            //console.log(res);
            this.getDistance(res['address'],ipCity).subscribe((val)=>{
              let dist = 0
              if(val['status']!="REQUEST_DENIED") dist = val['rows'][0]['elements'][0]["distance"]["value"];
              else if(this.getLower(res['address']).search(ipCity)!=-1) {
                dist = 0;
                val['origin_addresses']=[ipCity+" , france"];
              } else {
                dist = 100000;
                val['origin_addresses']=["suisse","usa"];
              }
              if(dist < 65000) {
                this.calcTrust(haveName,haveSurname,true,'pagesjunes');
                this.address = res['address'];
                this.addressFound = true;
                this.emitAddress();
                this.emitAddressFound();
                if(res['phone'].search(/(\d{2} ){4}\d{2}/gi)!=-1) {
                  this.phone = res['phone'];
                  this.phoneFound = true;
                  this.emitPhone();
                  this.emitPhoneFound();
                }
                this.matchOn['pagesjaunes']['haveResult'] = true;
              }
              this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
            });
          } else {
            this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
          }
        });
      } else if(match[ind]['site'].search('instagram')!=-1 && !this.pic && !this.matchOn['instagram']['haveResult']) { 
        //alert("je dois chercher le détail pour "+match[ind]['site']);
      } else {
        this.getDetails(match,ind+1,ipCity,nom,prenom,mail,haveName,haveSurname);
      }
    } else {
      this.checkIfDone(mail,prenom,nom,ipCity,haveName,haveSurname);
    }
  }

  checkIfDone(mail:string,prenom:Prenom,nom:Nom,ipCity:string,haveName:boolean,haveSurname:boolean) {
    let c = 0;
    let keys = Object.keys(this.matchOn);
    keys.forEach(key => {
      if(this.matchOn[key]['nb']==0) c++;
    });

    let missing:Array<string> = [];
    if(!this.jobFound) missing.push('job');
    if(!this.addressFound) missing.push('address');
    if(!this.fromFound) missing.push('from');
    if(!this.companyFound) missing.push('company');
    if(!this.phoneFound) missing.push('phone');
    if(!this.picFound) missing.push('pic');

    //console.log("Missing:");
    //console.log(missing);

    let nbMissing = missing.length;
    //console.log("nbMissing: "+nbMissing+"\nlastLoop:"+this.lastLoop);

    if(nbMissing >= 2 && this.nbLoop<10) {
      if(missing.indexOf('from')!=-1 && missing.indexOf('address')==-1) nbMissing--;
      if(missing.indexOf('address')!=-1 && missing.indexOf('from')==-1) nbMissing--;
      if(missing.indexOf('company')!=-1 && missing.indexOf('job')==-1) nbMissing--;
      if(missing.indexOf('job')!=-1 && missing.indexOf('company')==-1) nbMissing--;
      if(missing.indexOf('phone')!=-1) nbMissing--;

      //console.log("nbMissing after clean: "+nbMissing);

      if(nbMissing>0) {
        //il manque trop d'info je doit refaire la recherche
        this.nbLoop++;
        this.searchGoogle(mail,prenom,nom,ipCity,haveName,haveSurname);
      } else if (!this.lastLoop) {
        this.lastLoop = true;
        this.nbLoop++;
        this.searchGoogle(mail,prenom,nom,ipCity,haveName,haveSurname);
      } else {
        this.endSearch()
      }
    } else {
      //c'est bon il manque pas grand chose
      this.endSearch()
    }
  }

  getUrlToSearch(mail:string,prenom:Prenom,nom:Nom,ipCity:string,haveName:boolean,haveSurname:boolean):string {
    const KEY = "AIzaSyAIoIyVAsEUs9E49xBFqnawsde4OaYJfRU";
    let keys = Object.keys(this.matchOn);
    let exclude = [];
    let include = [];
    let url= '';
    keys.forEach(key => {
      if(this.matchOn[key]['nb'] > 3 || this.matchOn[key]['haveResult']) exclude.push(this.matchOn[key]['site']);
    });

    //console.log(this.matchOn);

    let subMail = mail.substring(0,mail.search('@'));
    let q:string;
    if(haveName && haveSurname) {
      if(this.nbLoop < 2) q = '&q='+prenom.name+'+'+nom.name;
      else q = '&q='+prenom.name+'+'+nom.name+'+'+ipCity;
    } else if(haveName) {
      if(this.nbLoop>5) q = '&q='+subMail+'+'+nom.name+'+'+ipCity;
      else q = '&q='+mail+'+'+nom.name+'+'+ipCity;
    } else if(haveSurname) {
      if(this.nbLoop>5) q = '&q='+subMail+'+'+prenom.name+'+'+subMail+'+'+ipCity;
      else q = '&q='+mail+'+'+prenom.name+'+'+subMail+'+'+ipCity;
    } else {
      if(this.nbLoop>5) q = '&q='+subMail+'+'+ipCity;
      else q = '&q='+mail+'+'+ipCity;
    }

    if(exclude.length>0 || this.nbLoop>0) {
      if(exclude.length>=4) {//si j'ai plus de la moitiée qui est exclu je cible les sites
        //je regarde ce qu'il manque
        if(!this.jobFound) {
          if(this.matchOn['linkedin']['nb']<=5 && include.indexOf(this.matchOn['linkedin']['site'])==-1) include.push(this.matchOn['linkedin']['site']);
        }
        if(!this.addressFound) {
          if(this.matchOn['118712']['nb']<=5 && include.indexOf(this.matchOn['118712']['site'])==-1) include.push(this.matchOn['118712']['site']);
          if(this.matchOn['pagesjaunes']['nb']<=5 && include.indexOf(this.matchOn['pagesjaunes']['site'])==-1) include.push(this.matchOn['pagesjaunes']['site']);
        }
        if(!this.fromFound) {
          if(this.matchOn['118712']['nb']<=5 && include.indexOf(this.matchOn['118712']['site'])==-1) include.push(this.matchOn['118712']['site']);
          if(this.matchOn['pagesjaunes']['nb']<=5 && include.indexOf(this.matchOn['pagesjaunes']['site'])==-1) include.push(this.matchOn['pagesjaunes']['site']);
          if(this.matchOn['linkedin']['nb']<=5 && include.indexOf(this.matchOn['linkedin']['site'])==-1) include.push(this.matchOn['linkedin']['site']);
          if(this.matchOn['facebook']['nb']<=5 && include.indexOf(this.matchOn['facebook']['site'])==-1) include.push(this.matchOn['facebook']['site']);
        }
        if(!this.companyFound) {
          if(this.matchOn['linkedin']['nb']<=5 && include.indexOf(this.matchOn['linkedin']['site'])==-1) include.push(this.matchOn['linkedin']['site']);
          if(this.matchOn['facebook']['nb']<=5 && include.indexOf(this.matchOn['facebook']['site'])==-1) include.push(this.matchOn['facebook']['site']);
        }
        if(!this.phoneFound) {
          if(this.matchOn['118712']['nb']<=5 && include.indexOf(this.matchOn['118712']['site'])==-1) include.push(this.matchOn['118712']['site']);
          if(this.matchOn['pagesjaunes']['nb']<=5 && include.indexOf(this.matchOn['pagesjaunes']['site'])==-1) include.push(this.matchOn['pagesjaunes']['site']);
        }
        if(!this.picFound) {
          if(this.matchOn['linkedin']['nb']<=5 && include.indexOf(this.matchOn['linkedin']['site'])==-1) include.push(this.matchOn['linkedin']['site']);
          if(this.matchOn['facebook']['nb']<=5 && include.indexOf(this.matchOn['facebook']['site'])==-1) include.push(this.matchOn['facebook']['site']);
          if(this.matchOn['instagram']['nb']<=5 && include.indexOf(this.matchOn['instagram']['site'])==-1) include.push(this.matchOn['instagram']['site']);
          if(this.matchOn['gendarmerie']['nb']<=5 && include.indexOf(this.matchOn['gendarmerie']['site'])==-1) include.push(this.matchOn['gendarmerie']['site']);
        }

        let siteSearch = "&siteSearch="+include.join(' ')+"&siteSearchFilter=i";

        url = "https://customsearch.googleapis.com/customsearch/v1?key="+KEY+"&cx=efa3605c760f38e94&gl=fr"+siteSearch+q;
      } else {
        let siteSearch = "&siteSearch="+exclude.join(' ')+"&siteSearchFilter=e";
        url = "https://customsearch.googleapis.com/customsearch/v1?key="+KEY+"&cx=efa3605c760f38e94&gl=fr"+siteSearch+q;
      }
    } else {
      url = "https://customsearch.googleapis.com/customsearch/v1?key="+KEY+"&cx=efa3605c760f38e94&gl=fr&q="+mail;
    }
    
    //console.log(url);

    return url;
  }

  endSearch() {
    this.matchOn = {
      'linkedin':{'nb':0,'haveResult':false,'site':"linkedin.com"},
      '118712':{'nb':0,'haveResult':false,'site':"annuaire.118712.fr"},
      'pagesjaunes':{'nb':0,'haveResult':false,'site':'pagesjaunes.fr'},
      'gendarmerie':{'nb':0,'haveResult':false,'site':'gendarmerie-paca.fr'},
      'facebook':{'nb':0,'haveResult':false,'site':'facebook.com'},
      'instagram':{'nb':0,'haveResult':false,'site':'instagram.com'}
    };
    this.isDone = true;
    this.emitIsDone();
    this.nbLoop = 0;
    this.lastLoop = false;
  }

  resetValues() {
    this.picFound = false;
    this.addressFound = false;
    this.jobFound = false;
    this.fromFound = false;
    this.companyFound = false;
    this.phoneFound = false;
    this.isDone = false;
    this.lastLoop = false;
    this.nbLoop = 0;
    this.trustValue = 0.5;
    this.matchOn = {
      'linkedin':{'nb':0,'haveResult':false,'site':"linkedin.com"},
      '118712':{'nb':0,'haveResult':false,'site':"annuaire.118712.fr"},
      'pagesjaunes':{'nb':0,'haveResult':false,'site':'pagesjaunes.fr'},
      'gendarmerie':{'nb':0,'haveResult':false,'site':'gendarmerie-paca.fr'},
      'facebook':{'nb':0,'haveResult':false,'site':'facebook.com'},
      'instagram':{'nb':0,'haveResult':false,'site':'instagram.com'}
    };

    this.emitAddressFound()
    this.emitCompanyFound();
    this.emitFromFound();
    this.emitJobFound();
    this.emitPhoneFound();
    this.emitPicFound();
    this.emitIsDone();
    this.emitTrust();
  }

  getLower(str:string):string {
    return str.replace(/ /gi,'').replace(/[àâä]/gi,'a').replace(/[éèëê]/gi,'e').replace(/[îï]/gi,'i').replace(/[ôö]/gi,'a').replace(/[ùûü]/gi,'u').toLowerCase();
  }

  getDistance(origin:string,destination:string) {
    //console.log("origin: "+origin+" destination: "+destination);
    if(destination.length == 0 || destination=="none") destination = "FRANCE";
    if(origin.length == 0 || destination=="none") origin = destination == "FRANCE" ? "PORTUGAL" : "FRANCE";
    return this.http.get('https://api.distancematrix.ai/maps/api/distancematrix/json?origins='+origin+'&destinations='+destination+'&key=CItxBBYYf44w624KYCLAHUW4ymiq1');
  }

  calcTrust(haveName:boolean,haveSurname:boolean,geoMatch:boolean,from:string) {
    //console.log("je calcule le trust");

    let score = 0.5;

    score+=this.nbLoop/20;
    switch(from) {
      case 'facebook':
        if(this.nbLoop==0) score += 0.3;
        if(haveName && haveSurname) {
          if(geoMatch) score+=0.5;
          else score-=0.2;
        } else if(haveName || haveSurname) {
          if(geoMatch) score+=0.075;
          else score-=0.4;
        } else {
          score-=0.8;
        }
        break;
      case 'linkedin':
        if(this.nbLoop==0) score += 0.4;
        if(haveName && haveSurname) {
          if(geoMatch) score+=1;
          else score-=0.4;
        } else if(haveName || haveSurname) {
          if(geoMatch) score+=0.075;
          else score-=0.6;
        } else {
          score-=0.7;
        }
        break;
      case 'instagram':
        break;
      case '118712':
        if(this.nbLoop==0) score += 0.3;
        if(haveName && haveSurname) {
          if(geoMatch) score+=0.5;
          else score+=0.2;
        } else if(haveName || haveSurname) {
          if(geoMatch) score+=0.075;
          else score-=0.2;
        } else {
          score-=0.5;
        }
        break;
      case 'pagesjaunes':
        if(this.nbLoop==0) score += 0.3;
        if(haveName && haveSurname) {
          if(geoMatch) score+=0.5;
          else score+=0.2;
        } else if(haveName || haveSurname) {
          if(geoMatch) score+=0.075;
          else score-=0.2;
        } else {
          score-=0.5;
        }
        break;
      case 'gendarmerie':
        if(this.nbLoop==0) score += 0.3;
        if(haveName && haveSurname) {
          if(geoMatch) score+=0.5;
          else score+=0.2;
        } else if(haveName || haveSurname) {
          if(geoMatch) score+=0.075;
          else score-=0.2;
        } else {
          score-=0.5;
        }
        break;
    }

    this.trustValue = (this.trustValue+score)/2;

    if(this.trustValue>1) this.trustValue=1;
    else if(this.trustValue<0) this.trustValue = 0;

    this.emitTrust();
  }
}
