import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Connection, Nom, NomList, Prenom, PrenomList } from '../models/dataset.model';
import { DataSetService } from '../services/data-set.service';
import { IpinfoService } from '../services/ipinfo.service';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  animOn:boolean;

  isDone:boolean;
  inProgress:boolean;
  noDataFound:boolean;
  isDoneSubscription: Subscription;

  trustValue:number;
  trustValueSubscription: Subscription;

  mail:string;
  nom:Nom;
  prenom:Prenom;

  phone:string;
  company:string;
  pic:string;
  job:string;
  from:string;
  address:string;
  
  companySubscription: Subscription;
  picSubscription: Subscription;
  jobSubscription: Subscription;
  fromSubscription: Subscription;
  addressSubscription: Subscription;
  phoneSubscription: Subscription;

  phoneFound:boolean;
  companyFound:boolean;
  picFound:boolean;
  jobFound:boolean;
  fromFound:boolean;
  addressFound:boolean;

  companyFoundSubscription: Subscription;
  picFoundSubscription: Subscription;
  jobFoundSubscription: Subscription;
  fromFoundSubscription: Subscription;
  addressFoundSubscription: Subscription;
  phoneFoundSubscription: Subscription;

  haveName:boolean;
  haveSurname:boolean;

  prenoms:PrenomList;
  prenomsSubscription: Subscription;

  noms:NomList;
  nomsSubscription: Subscription;

  connection:Connection;
  connSubscription: Subscription;

  constructor(private datas: DataSetService, private search:SearchService, private ipInfo: IpinfoService) {
    this.haveName = false;
    this.haveSurname = false;
    this.inProgress = false;
    this.noDataFound = false;
    this.trustValue = 0.5;
    this.animOn=true;
   }

  ngOnInit(): void {
    this.prenomsSubscription = this.datas.prenomsSubject.subscribe((val)=>{
      this.prenoms = val;
    });
    this.datas.emitPrenomsSubject();

    this.nomsSubscription = this.datas.nomsSubject.subscribe((val)=>{
      this.noms = val;
    });
    this.datas.emitNomsSubject();

    this.connSubscription = this.ipInfo.connSubject.subscribe((val)=>{
      this.connection = val;
    });
    this.ipInfo.emitConnection();

    this.companySubscription = this.search.companySubject.subscribe((val)=>{
      this.company = val;
    });
    this.search.emitCompany();

    this.companyFoundSubscription = this.search.companyFoundSubject.subscribe((val)=>{
      this.companyFound = val;
    });
    this.search.emitCompanyFound();

    this.picSubscription = this.search.picSubject.subscribe((val)=>{
      this.pic = val;
    });
    this.search.emitPic();

    this.picFoundSubscription = this.search.picFoundSubject.subscribe((val)=>{
      this.picFound = val;
    });
    this.search.emitPicFound();

    this.jobSubscription = this.search.jobSubject.subscribe((val)=>{
      this.job = val;
    });
    this.search.emitJob();

    this.jobFoundSubscription = this.search.jobFoundSubject.subscribe((val)=>{
      this.jobFound = val;
    });
    this.search.emitJobFound();

    this.fromSubscription = this.search.fromSubject.subscribe((val)=>{
      this.from = val;
    });
    this.search.emitFrom();

    this.fromFoundSubscription = this.search.fromFoundSubject.subscribe((val)=>{
      this.fromFound = val;
    });
    this.search.emitFromFound();

    this.addressSubscription = this.search.addressSubject.subscribe((val)=>{
      this.address = val;
    });
    this.search.emitAddress();

    this.addressFoundSubscription = this.search.addressFoundSubject.subscribe((val)=>{
      this.addressFound = val;
    });
    this.search.emitAddressFound();

    this.phoneSubscription = this.search.phoneSubject.subscribe((val)=>{
      this.phone = val;
    });
    this.search.emitPhone();

    this.phoneFoundSubscription = this.search.phoneFoundSubject.subscribe((val)=>{
      this.phoneFound = val;
    });
    this.search.emitPhoneFound();

    this.isDoneSubscription = this.search.isDoneSubject.subscribe((val)=>{
      this.isDone = val;
      if(val && this.inProgress) {
        this.inProgress = false;
      }
    });
    this.search.emitPhoneFound();

    this.trustValueSubscription = this.search.trustValueSubject.subscribe((val)=>{
      this.trustValue = val;
      if(document.getElementById('trust-container')) {
        document.getElementById('trust-container').style.transform = ('scaleX('+val+')');
      }
    });
    this.search.emitTrust();
  }

  ngOnDestroy(): void {
    this.prenomsSubscription.unsubscribe();
    this.nomsSubscription.unsubscribe();
    this.connSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
    this.picSubscription.unsubscribe();
    this.jobSubscription.unsubscribe();
    this.fromSubscription.unsubscribe();
    this.addressSubscription.unsubscribe();
    this.companyFoundSubscription.unsubscribe();
    this.picFoundSubscription.unsubscribe();
    this.jobFoundSubscription.unsubscribe();
    this.fromFoundSubscription.unsubscribe();
    this.addressFoundSubscription.unsubscribe();
  }

  mailFocus() {
    this.animOn = false;
  }

  mailChange(event:any) {
    this.animOn = false;
    this.mail = event.target.value.toLowerCase().trim();
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(re.test(this.mail.toLowerCase())) {
      if(this.connection) {
        if(this.connection.score < -300 && this.connection.city) {
          if(!this.inProgress) {

            event.target.nextSibling.classList.remove('active');
            event.target.nextSibling.nextSibling.classList.remove('active');

            this.inProgress = true;
            this.noDataFound = false;
            this.haveName = false;
            this.haveSurname = false;

            let toCheck = this.mail.substr(0,this.mail.indexOf('@'))
                                    .replace(/[àâä]/gi,'a')
                                    .replace(/[éèëê]/gi,'e')
                                    .replace(/[îï]/gi,'i')
                                    .replace(/[ôö]/gi,'a')
                                    .replace(/[ùûü]/gi,'u')
                                    .replace(/[']/gi,'')
                                    .split(/[,.-_]/);
                                    
            let prenom:Array<Prenom>;
            let nom:Array<Nom>;

            this.checkIfPrenom(toCheck).then((res)=>{
              prenom = res;
              //console.log(prenom);
              
              this.checkIfNom(toCheck).then((res)=>{
                nom = res;
                //console.log(nom)

                setTimeout(()=>{
                  this.findNameSurname(nom,prenom,toCheck).then(()=>{
                    //console.log(this.nom.name+" "+this.prenom.name)
                    this.search.searchGoogle(this.mail,this.prenom,this.nom,this.connection.city,this.haveName,this.haveSurname);
                    let isDoneSubscription = this.search.isDoneSubject.subscribe((val)=>{
                      if(val) {
                        isDoneSubscription.unsubscribe();
                        let c = 0;
                        c = this.phoneFound ? c+1 : c;
                        c = this.companyFound ? c+1 : c;
                        c = this.picFound ? c+1 : c;
                        c = this.jobFound ? c+1 : c;
                        c = this.fromFound ? c+1 : c;
                        c = this.addressFound ? c+1 : c;
                        this.noDataFound = c==0 ? true : false;
                      }
                    });
                  });
                },500);
              });
            });
          }
        } else {
          event.target.value = '';
          event.target.nextSibling.classList.add('active');
          event.target.nextSibling.nextSibling.classList.remove('active');
        }
      } else {
        event.target.value = '';
        event.target.nextSibling.classList.add('active');
        event.target.nextSibling.nextSibling.classList.remove('active');
      }
    } else {
      event.target.value = '';
      event.target.nextSibling.classList.remove('active');
      event.target.nextSibling.nextSibling.classList.add('active');
    }
  }

  findNameSurname(nom:Array<Nom>,prenom:Array<Prenom>,toCheck:Array<string>) {
    function getBiggest(array:Array<any>,mail:string) {
      let max = 0;
      let id = 0;
      let pos = 0;
      let name = '';
      for (let i = 0; i < array.length; i++) {
        if(array[i].name.length>max) {
          max = array[i].name.length;
          id=i;
          pos=mail.search(array[i].name);
          name = array[i].name;
        }
      }
      return {'name':name, 'id':id, 'pos':pos, 'length':max};
    }

    function getMissingPos(biggestN:any,biggestP:any):number {
      if(biggestP.pos < biggestN.pos) {
        if(biggestP.pos + biggestP.length == biggestN.pos) {
          return biggestN.pos+biggestN.length;
        } else {
          return biggestP.pos + biggestP.length
        }
      } else {
        if(biggestN.pos + biggestN.length == biggestP.pos) {
          return biggestP.pos+biggestP.length;
        } else {
          return biggestN.pos + biggestN.length
        }
      }
    }
    /*
    j'ai une liste de nom et prénom 
    si le tableau est length = 2 je check si c'est le nom et prenom
    ->si biggest prenom & biggest nom > to check
    */
    return new Promise((resolve,rejects)=>{
      let resPrenom='';
      let resNom='';
      let mail = this.mail.substr(0,this.mail.indexOf('@')).replace(/[^a-zA-Z]/gi,'').replace(/[àâä]/gi,'a').replace(/[éèëê]/gi,'e').replace(/[îï]/gi,'i').replace(/[ôö]/gi,'a').replace(/[ùûü]/gi,'u').toLowerCase();
      let biggestN = getBiggest(nom,mail);
      let biggestP = getBiggest(prenom,mail);
      
      if (biggestN.name == biggestP.name && biggestP.length > 1 && biggestN.length > 1) { //ici le + gros prenom et le + gros nom sont =
        //je check si le 2nd name est dans le tocheck
        //console.log("same P and N = "+biggestN.name);
        let tempNom = [];
        for (let i = 0; i < nom.length; i++) {
          if(i != biggestN.id) tempNom.push(nom[i]);
        }
        let secondBiggest = getBiggest(tempNom,mail);
        //console.log("secondBiggest = "+secondBiggest.name);
        //console.log(toCheck);
        if(toCheck.indexOf(secondBiggest.name)!=-1 || toCheck.length==1) {
          biggestN = secondBiggest;
        } else {
          prenom.splice(biggestP.id,1);
          biggestP = getBiggest(prenom,mail);
          while(biggestN.name.search(biggestP.name)!=-1 && biggestP.length > 1) {
            prenom.splice(biggestP.id,1);
            biggestP = getBiggest(prenom,mail);
          }
        }
      }else if(biggestN.name.search(biggestP.name)!=-1 && biggestP.length > 1) {//ici le biggest name contient biggest p
        prenom.splice(biggestP.id,1);
        biggestP = getBiggest(prenom,mail);
        while(biggestN.name.search(biggestP.name)!=-1 && biggestP.length > 1) {//je retire le biggest p jusqu'a trouvé le p non contenu dans le nom
          prenom.splice(biggestP.id,1);
          biggestP = getBiggest(prenom,mail);
        }
      } else if(biggestP.name.search(biggestN.name)!=-1  && biggestN.length > 1) {//ici le biggest name est contenu par biggest p
        nom.splice(biggestN.id,1);
        biggestN = getBiggest(nom,mail);
        while(biggestN.name.search(biggestN.name)!=-1 && biggestN.length > 1) {
          nom.splice(biggestN.id,1);
          biggestN = getBiggest(nom,mail);
        }
      } else if (biggestP.length <= 1) {
        biggestP = {'name':'', 'id':-1, 'pos':-1, 'length':-1};
      } else if (biggestN.length <= 1) {
        biggestN = {'name':'', 'id':-1, 'pos':-1, 'length':-1};
      }
  
      if(biggestN.length+biggestP.length == mail.length) {//ici je suis quasiment sur d'avoir le bon nom et prénom
        resPrenom = biggestP.name;
        resNom = biggestN.name;
      } else if (biggestN.length+biggestP.length < mail.length) {//il reste des charactères dans le mail
        /* je vais regarder ou est situé la partie non prise 
        J'emet l'hypotèse que tout les prénoms sont enregistré dans ma base de donnée et donc je rajoute au nom le reste
        titouanclavet -> tito uan calvet
        agnesscottodicesare -> agnes scotto dicesare
        ocalvet -> o calvet*/
        let posMissing = getMissingPos(biggestN,biggestP);
        let lengthLeft = mail.length-(biggestN.length+biggestP.length);

        //s'il ne reste qu'une lettre je l'ignore
        if(lengthLeft > 1) {
          if(biggestN.pos < posMissing) {
            //je vérifie que le reste est collé au nom
            if(posMissing == biggestN.pos+biggestN.length) {
              resNom = biggestN.name+""+mail.substring(posMissing,biggestP.pos);
              resPrenom = biggestP.name;
            }
          } else {
            if(biggestN.pos == posMissing+lengthLeft) {
              resNom = mail.substring(posMissing,biggestN.pos)+""+biggestN.name;
              resPrenom = biggestP.name;
            }
          }
        } else {
          resPrenom = biggestP.name;
          resNom = biggestN.name;
        }
      } else {//il y a trop de charactère
        /* il y a soit  */
      }
  
      this.nom = new Nom(resNom,0);
      this.prenoms.isIn(resPrenom).then((ind)=>{
        if(ind!=-1) {
          this.prenom = this.prenoms.datas[ind];
        } else {
          this.prenom = new Prenom(resPrenom,'nc','',0);
        }
        if(resNom.length>0) this.haveName = true;
        if(resPrenom.length>0) this.haveSurname = true;
        resolve(true);
      });
    }); 
    
  }

  checkIfPrenom(toCheck:Array<string>):Promise<Array<Prenom>> {
    return new Promise((resolve, reject) => {
      let res = [];
      let resNames = [];
      for(let x=0;x<toCheck.length;x++) {
        this.prenoms.isIn(toCheck[x]).then((indX)=>{
          if(indX!=-1 && resNames.indexOf(this.prenoms[indX])==-1) {
                //console.log("prenom "+toCheck[x]+" find: "+indX+" sexe:"+this.prenoms.datas[indX].gender+" from: "+this.prenoms.datas[indX].country+" occ:"+this.prenoms.datas[indX].occurence);
                res.push(this.prenoms.datas[indX]);
                resNames.push(this.prenoms.datas[indX].name);
          } else {
            for(let i=1;i<toCheck[x].length+1;i++) {
    
              this.prenoms.isIn(toCheck[x].slice(0,i)).then((check1)=>{
                if(check1!=-1 && resNames.indexOf(this.prenoms[check1])==-1) {
                  res.push(this.prenoms.datas[check1]);
                  resNames.push(this.prenoms.datas[check1].name);
                  //console.log("prenom "+toCheck[x].slice(0,i)+" find: "+check1+" sexe:"+this.prenoms.datas[check1].gender+" from: "+this.prenoms.datas[check1].country+" occ:"+this.prenoms.datas[check1].occurence);
                } 
              });

              this.prenoms.isIn(toCheck[x].slice(i-1)).then((check2)=>{
                if(check2!=-1 && resNames.indexOf(this.prenoms[check2])==-1) {
                  res.push(this.prenoms.datas[check2]);
                  resNames.push(this.prenoms.datas[check2].name);
                  //console.log("prenom "+toCheck[x].slice(i-1)+" find: "+check2+" sexe:"+this.prenoms.datas[check2].gender+" from: "+this.prenoms.datas[check2].country+" occ:"+this.prenoms.datas[check2].occurence);
                }
              });
            }
          }
        });
      }
      setTimeout(() => {
        resolve(res);
      }, 1500);
    });
  }

  checkIfNom(toCheck:Array<string>):Promise<Array<Nom>> {
    return new Promise((resolve, reject) => {
      let res = [];
      let resNames = [];
      for(let x=0;x<toCheck.length;x++) {

        this.noms.isIn(toCheck[x]).then((indX)=>{
          if(indX!=-1 && resNames.indexOf(this.noms[indX])==-1) {
                //console.log("nom "+toCheck[x]+" find: "+indX+" occ:"+this.noms.datas[indX].count);
                res.push(this.noms.datas[indX]);
                resNames.push(this.noms.datas[indX].name);
          } else {
            for(let i=1;i<toCheck[x].length+1;i++) {
    
              this.noms.isIn(toCheck[x].slice(0,i)).then((check1)=>{
                if(check1!=-1 && resNames.indexOf(this.noms[check1])==-1) {
                  res.push(this.noms.datas[check1]);
                  resNames.push(this.noms.datas[check1].name);
                  //console.log("nom "+toCheck[x].slice(0,i)+" find: "+check1+" occ:"+this.noms.datas[check1].count);
                } 
              });

              this.noms.isIn(toCheck[x].slice(i-1)).then((check2)=>{
                if(check2!=-1 && resNames.indexOf(this.noms[check2])==-1) {
                  res.push(this.noms.datas[check2]);
                  resNames.push(this.noms.datas[check2].name);
                  //console.log("nom "+toCheck[x].slice(i-1)+" find: "+check2+" occ:"+this.noms.datas[check2].count);
                }
              });
            }
          }
        });
      }
      setTimeout(() => {
        resolve(res);
      }, 1500);
    });
  }

}
