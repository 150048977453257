import { Injectable } from '@angular/core';
import { Connection, Data, DataSet, NomList, PrenomList } from '../models/dataset.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSetService {

  country:DataSet;
  language:DataSet;
  os:DataSet;
  prenoms:PrenomList;
  noms:NomList;

  osScore:number;
  languageScore:number;
  countryScore:number;
  
  analysisDone:boolean;
  analysisDoneSubject:Subject<boolean>;

  osSubject: Subject<DataSet>;
  langSubject: Subject<DataSet>;
  countrySubject: Subject<DataSet>;
  prenomsSubject: Subject<PrenomList>;
  nomsSubject: Subject<NomList>;
  osScoreSubject: Subject<number>;
  langScoreSubject: Subject<number>;
  countryScoreSubject: Subject<number>;

  constructor(private http:HttpClient) {
    this.country = null;
    this.language = null;
    this.os = null;
    this.countryScore = 0;
    this.languageScore = 0;
    this.osScore = 0;
    this.analysisDone=false;

    this.http.get('assets/dataset/os.ob', {responseType: 'text'}).subscribe((data) => {
      this.os = new DataSet(data);
      this.emitOsSubject();
    });
    this.http.get('assets/dataset/language.ob', {responseType: 'text'}).subscribe((data) => {
      this.language = new DataSet(data);
      this.emitLangSubject();
    });
    this.http.get('assets/dataset/ipgeo.ob', {responseType: 'text'}).subscribe((data) => {
      this.country = new DataSet(data);
      this.emitLangSubject();
    });
    this.http.get('assets/dataset/prenoms.ob', {responseType: 'text'}).subscribe((data) => {
      this.prenoms = new PrenomList(data);
      this.emitPrenomsSubject();
    });
    this.http.get('assets/dataset/noms.ob', {responseType: 'text'}).subscribe((data) => {
      this.noms = new NomList(data);
      this.emitNomsSubject();
    });

    this.osSubject = new Subject<DataSet>();
    this.langSubject = new Subject<DataSet>();
    this.countrySubject = new Subject<DataSet>();
    this.osScoreSubject = new Subject<number>();
    this.langScoreSubject = new Subject<number>();
    this.countryScoreSubject = new Subject<number>();
    this.prenomsSubject = new Subject<PrenomList>();
    this.nomsSubject = new Subject<NomList>();
    this.analysisDoneSubject = new Subject<boolean>();

    //this.correctDataset();
  }

  emitOsSubject() {
    this.osSubject.next(this.os);
  }

  emitLangSubject() {
    this.langSubject.next(this.language);
  }

  emitCountrySubject() {
    this.countrySubject.next(this.country);
  }

  emitOsScoreSubject() {
    this.osScoreSubject.next(this.osScore);
  }

  emitLangScoreSubject() {
    this.langScoreSubject.next(this.languageScore);
  }

  emitCountryScoreSubject() {
    this.countryScoreSubject.next(this.countryScore);
  }

  emitAnalysisDoneSubject() {
    this.analysisDoneSubject.next(this.analysisDone);
  }

  emitPrenomsSubject() {
    this.prenomsSubject.next(this.prenoms);
  }

  emitNomsSubject() {
    this.nomsSubject.next(this.noms);
  }

  checkConnection(conn:Connection) {
    //console.log("start score: "+conn.score);
    /*
    part 1:
    check if datas in dataBase 
        ->  get Score and loss
        -> score = (score + (findScore * loss))/2
    part 2:
    check logic infos
      ipGeo Match with language (-100) (+200)
      etc...
    */
    return new Promise((resolve, reject) => {
      let sC = this.country.getScore(conn.countryCode.toLowerCase());
      let sO = this.os.getScore(conn.os.toLowerCase());
      let sL = this.language.getScore(conn.language.toLowerCase());
      if(sC) {
        this.countryScore = sC*this.country.loss;
        conn.score = Math.floor((conn.score+sC*this.country.loss)/2);
      }
      if(sO) {
        this.osScore = sO*this.os.loss;
        conn.score = Math.floor((conn.score+sO*(this.os.loss+0.2))/2);
      }
      if(sL) {
        this.languageScore = sL*this.language.loss;
        conn.score = Math.floor((conn.score+sL*this.language.loss)/2);
      }

      //PART 2:
      if(conn.language.indexOf(conn.countryCode)==-1) conn.score+=400;
      this.analysisDone = true;
      this.emitAnalysisDoneSubject();
      this.emitOsScoreSubject();
      this.emitCountryScoreSubject();
      this.emitLangScoreSubject();
      //console.log("end score: "+conn.score);
      resolve(true);
    });
  }

  correctDataset() {
    this.http.get('assets/dataset/patronymes.csv', {responseType: 'text'}).subscribe((data) => {
      let temp:string = (data);
      console.log(temp);
      temp = temp.replace(/{"/gi,'{"name":"');
      temp = temp.replace(/\n/gi,'},{"');
      temp = temp.toLowerCase();
      console.log(temp);
    });
  }
}
