<div class="container">
    <div class="col-span-2 center border-b">Personal Data Inspector</div>
    <div class="col-span-2 empty"></div>
    <div class="col-span-2 input-container">
        <input type="text" name="mail" [ngClass]="{'active':animOn}" id="mailInput" placeholder="Enter your MailBox" autocomplete="false" (focus)="mailFocus()" (change)="mailChange($event)">
        <span class="wrong bad">You need a clean Threat Analisys to use this service</span>
        <span class="wrong mail">Your mailbox isn't valid</span> 
    </div>
    <div class="empty col-span-2"></div>
    <div class="col-span-2 center" *ngIf="haveName">
        Bonjour <span *ngIf="haveSurname; else noSurname">
                    <span *ngIf="prenom.gender=='f'; else elseGender">Mme</span>
                    <ng-template #elseGender>
                        <span *ngIf="prenom.gender=='m'; else elseGenderNc">Mr</span>
                    </ng-template>
                    <ng-template #elseGenderNc>
                        <span>{{ prenom.name | titlecase}}</span>
                    </ng-template>
                </span>
                <ng-template #noSurname>
                    <span>Mme/Mr</span>
                </ng-template>
                 {{ nom.name | titlecase }}
    </div>
    <div class="empty col-span-2" *ngIf="picFound" ></div>
    <div *ngIf="picFound" class="picInfo col-span-2" [style.background-image]="'url(' + pic + ')'"></div>
    <div class="empty col-span-2" *ngIf="fromFound"></div>
    <div class="label col-span-2" *ngIf="fromFound">From:</div>
    <div class="fromInfo col-span-2" *ngIf="fromFound">{{ from }}</div>
    <div class="empty col-span-2" *ngIf="jobFound"></div>
    <div class="label col-span-2" *ngIf="jobFound">Your Job:</div>
    <div class="jobInfo col-span-2" *ngIf="jobFound">{{ job }}</div>
    <div class="empty col-span-2" *ngIf="addressFound"></div>
    <div class="label col-span-2" *ngIf="addressFound">Your address:</div>
    <div class="addressInfo col-span-2" *ngIf="addressFound">{{ address }}</div>
    <div class="empty col-span-2" *ngIf="phoneFound"></div>
    <div class="label col-span-2" *ngIf="phoneFound">Your phone:</div>
    <div class="phoneInfo col-span-2" *ngIf="phoneFound">{{ phone }}</div>
    <div class="empty col-span-2" *ngIf="companyFound"></div>
    <div class="label col-span-2" *ngIf="companyFound">Your company:</div>
    <div class="companyInfo col-span-2" *ngIf="companyFound">{{ company }}</div>
    <div class="empty col-span-2" *ngIf="noDataFound"></div>
    <div class="col-span-2" *ngIf="noDataFound">No Personnal data found</div>
    <div class="col-span-2 tips" *ngIf="noDataFound">Tips: Using a VPN or a Proxy can false the result.</div>
    <div class="col-span-2 tips" *ngIf="noDataFound">Tips: A mailbox with lastname and firstname is more efficient.</div>
    <div *ngIf="inProgress" class="empty col-span-2"></div>
    <div *ngIf="inProgress" class="loading col-span-2"></div>
    <div class="empty col-span-2" *ngIf="isDone && !noDataFound || inProgress"></div>
    <div class="label col-span-2" *ngIf="isDone && !noDataFound || inProgress">Trust score:</div>
    <div class="col-span-2 trust" *ngIf="isDone && !noDataFound || inProgress">
        <div class="trust-container" id="trust-container"></div>
        <div class="trust-text"><span>Not reliable</span><span>Reliable</span></div>
    </div>
</div>