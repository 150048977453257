import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  version:string;
  details:string;

  constructor() {
    this.version = "1.5.0";
    this.details = "correct SEO sitechecker warning";
   }

  ngOnInit(): void {
    console.log("version: "+this.version);
  }

  printDetails() {
    console.log(this.details);
  }

}
