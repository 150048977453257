import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  localTitle:string;
  firstLoad:boolean;

  constructor(private title: Title) {
    title.setTitle("Calvet Romain");
    this.firstLoad = true;
  }

  ngOnInit() {
    setTimeout(() => {
      this.firstLoad = false;
    }, 14500);
  }
}