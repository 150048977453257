export class Categorie {

    name:string;
    theme:string;
    compList:Array<Competence>;
    isActive:boolean;

    constructor(name:string,theme:string,list:Array<Competence>) {
        this.name=name;
        this.compList = list;
        this.theme = theme;
        this.isActive = false;
    }

    setCompList(list:Array<Competence>) {
        this.compList = list;
    }

    addComp(comp:Competence) {
        this.compList.push(comp);
    }
}

export class Competence {

    name:string;
    categorie:Array<string>;
    level:number;
    isActive:boolean;
    exemple:string;

    constructor(name:string,cat:Array<string>,lvl:number,exemple:string) {
        this.name = name;
        this.categorie = cat;
        this.level = lvl;
        this.isActive = false;
        this.exemple = exemple;
    }
}

export class Theme {

    name:string;
    categorie:Array<Categorie>;
    isActive:boolean;

    constructor(name:string,cat:Array<Categorie>) {
        this.name = name;
        this.categorie = cat;
        this.isActive = false;
    }
}