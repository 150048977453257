<div class="container">
  <div id="left-panel"><app-connection></app-connection></div>
  <div id="center-panel"><app-cv></app-cv></div>
  <div id="right-panel">
    <div class="perso"><app-personal></app-personal></div>
    <div class="flux"><app-flux></app-flux></div>
  </div>
  <ng-container *ngIf="firstLoad"><app-intro></app-intro></ng-container>
</div>
<div class="responsive-low">
  <h1 style="padding: 3rem;">Désolé, mais le site n'est pas encore adapté aux petits écrans...</h1>
  <br>
  <h2 style="padding: 3rem;">Merci d'utiliser un écran plus grand ou de pivoter l'écran en mode paysage.</h2>
  <!--<app-mobile></app-mobile>-->
</div>
<div class="version">
  <app-version></app-version>
</div>

