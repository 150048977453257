export class Frame {
    n:number;
    time:string;
    source:string;
    destination:string;
    protocol:string;

    constructor(nb:number) {
        this.n = nb;
        this.source = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255));
        this.destination = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255));
        switch(Math.floor(Math.random() * Math.floor(4))) {
            case 0:
                this.protocol = "HTTP";
                break;
            case 1:
                this.protocol = "TCP";
                break;
            case 2:
                this.protocol = "DNS";
                break;
            case 3:
                this.protocol = "UDP";
                break;
            default:
                this.protocol = "TCP";
                break;
        }
        this.time = (Math.floor(Math.random() * Math.floor(2)) + Math.random()).toFixed(5);
    }
}

export class DataExemple {

    time:number;
    from:string;
    count:number;
    name:string;
    active:Array<boolean>;
    length:number;

    constructor(name:string) {
        this.time = new Date().getTime();
        this.from = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255));
        this.count = (Math.floor(Math.random() * 99865) + 1);
        this.name = name;
        this.length = name.length;
        this.active = new Array(5).fill(false);
    }
}