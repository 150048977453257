<div class="container" itemscope="" itemtype="https://schema.org/Person">
    <a href="http://www.calvetromain.fr" itemprop="url" style="display: none;">calvetromain.com</a>
    <div class="head col-span-2">
        <h1 style="display: none;">Cv intéractif de Calvet Romain</h1>
        <div id="name" itemprop="name">CALVET Romain</div>
        <div id="age">{{age}}ans</div> 
        <div id="title">
            <span itemprop="jobTitle">Cyber Security</span>
            <span itemprop="jobTitle">Cyber Defence</span>
            <span itemprop="jobTitle">SOAR engineer</span>
            <span itemprop="jobTitle">Project Manager</span>
        </div>
    </div>
    <div class="contact"><a class="mail" href="mailto:contact@calvetromain.fr" itemprop="email"><span class="icon"></span><span >contact@calvetromain.fr</span></a><a class="phone" href="tel:+33643870370"><span class="icon"></span><span itemprop="telephone">+33(0)6 43 87 03 70</span></a><div class="from"><span class="icon"></span><span><span itemprop="postalCode">13002 </span><span itemprop="addressLocality">Marseille</span></span></div></div>
    <div class="exemple row-span-4"><app-exemple></app-exemple></div>
    <div class="cont-title">Competences:</div>
    <div class="content" itemprop="knowsAbout">
        <div class="sub" *ngFor="let t of themeList; let x = index">
            <div [ngClass]="{'active':t.isActive ,'subTitle':true}">{{t.name}}</div>
            <div class="line" *ngFor="let cat of t.categorie; let i = index">
                <div [ngClass]="{'active':cat.isActive ,'cat':true}">{{cat.name}}</div>
                <div class="details-container">
                    <div [ngClass]="{'active': comp.isActive,'details':true}" [id]="comp.name" *ngFor="let comp of cat.compList; let j = index" (click)="clickDetails(comp,[x,i,j])">
                        <span>{{comp.name}}</span>
                        <div class="level">
                            <div [ngClass]="{'square':true, 'on':comp.level >= x}" *ngFor="let x of [1,2,3,4,5]"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="exp-title">Formations & Experiences:</div>
    <div class="experience col-span-2" (mousewheel)="mousewheel($event)"><app-experience></app-experience></div>
</div>