<div class="container first">
    <div class="left-panel"></div>
    <div class="center-panel">
        <div class="head col-span-2"></div>
        <div class="contact"></div>
        <div class="exemple row-span-4"></div>
        <div class="cont-title"></div>
        <div class="content"></div>
        <div class="exp-title"></div>
        <div class="experience col-span-2"></div>
    </div>
    <div class="right-panel"></div>
    <div class="text"><div>Bonjour et bienvenue sur mon CV.</div></div>
</div>
<div class="container second">
    <div class="left-panel"></div>
    <div class="center-panel">
        <div class="head col-span-2"></div>
        <div class="contact"></div>
        <div class="exemple row-span-4"></div>
        <div class="cont-title"></div>
        <div class="content"></div>
        <div class="exp-title"></div>
        <div class="experience col-span-2"></div>
    </div>
    <div class="right-panel"></div>
    <div class="text"><div>Cliquez sur les compétences...</div></div>
</div>
<div class="container third">
    <div class="left-panel"></div>
    <div class="center-panel">
        <div class="head col-span-2"></div>
        <div class="contact"></div>
        <div class="exemple row-span-4"></div>
        <div class="cont-title"></div>
        <div class="content"></div>
        <div class="exp-title"></div>
        <div class="experience col-span-2"></div>
    </div>
    <div class="right-panel"></div>
    <div class="text"><div>Et retrouvez un descriptif</div></div>
</div>